import { React, useEffect, useState, useRef, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import DiscountItem from './DiscountItem';
import Modal from '../Modal';
import * as api from "../../api/ProductsApi";

function Discounts(props) {

  const [apiTrigger, setApiTrigger] = useState(false)
  const [showForm, setShowForm] = useState(false);
  const [appliedDiscounts, setAppliedDiscounts] = useState(props.discounts);
  const itemIds = useMemo(() => appliedDiscounts.map((item) => item.id), [appliedDiscounts]); // ["1", "2", "3"]
  const [isOpen, setIsOpen] = useState(false);
  const [deleteDiscountId, setDeleteDiscountId] = useState('');

  function filterById(jsonObject, id) { return jsonObject.filter(function (jsonObject) { return (jsonObject['id'] == id); })[0]; }


  useEffect(() => {
    props.setItemDiscounts(appliedDiscounts);
  }, [appliedDiscounts]);

  const amountInput = useRef();
  const descInput = useRef();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addDiscountHandler = () => {
    let amount = amountInput.current.value;
    let description = descInput.current.value;
    if (amount.trim() !== "" && description.trim() !== "") {
      setAppliedDiscounts((prevState) => {
        return [...prevState,
        {
          "id": uuidv4(),
          "amount": props.type === "Markup" ? -amount : amount,
          "description": description,
        }]
      });
    }
    setShowForm(false);
  }

  const editDiscountHandler = (obj) => {
    const currentItemIndex = appliedDiscounts.findIndex((item) => item.id === obj.id);
    const updatedItem = { ...appliedDiscounts[currentItemIndex], amount: obj.amount, description: obj.description };
    const newItems = [...appliedDiscounts];
    newItems[currentItemIndex] = updatedItem;
    setAppliedDiscounts(newItems);
  }

  const deleteDiscountHandler = (id) => {
    setAppliedDiscounts((current) =>
      current.filter((item) => item.id !== id)
    );
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    console.log(appliedDiscounts);

    if (active.id !== over.id) {
      setAppliedDiscounts((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }


  return (
    <>
      <div>
        <div className='flex items-center justify-between'>
          <h2 className="text-slate-700 font-bold text-xl">
            {props.type === "Discount" && "Discounts"}
            {props.type === "Markup" && "Duties"}
          </h2>
          {!showForm &&

            <button className='btn flex items-center ml-auto' onClick={() => { setShowForm(true) }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
              <span className='ml-2'>
                {props.type === "Discount" && "Add Discount"}
                {props.type === "Markup" && "Add Duty"}
              </span>
            </button>
          }
        </div>
        {showForm && <div className='flex justify-between border-b border-slate-200 pb-6 pt-6'>
          <div className='flex items-center w-[75%]'>
            <div className="items-center w-full">
              <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor="Description">
                Description
              </label>
              <input required ref={descInput} className='border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5' id="Description" type="text" placeholder="Description" />
            </div>
            <div className="items-center ml-2">
              <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor="Amount">
                Amount (%)
              </label>
              <input required ref={amountInput} className='border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5' id="Amount" type="number" min="0" step="0.01" placeholder="0" />
            </div>
          </div>
          <div className='flex items-end'>
            <button className='btn flex items-center ml-auto mr-4' onClick={addDiscountHandler}>Add</button>
            <button className='btn flex bg-gray-200 hover:bg-gray-300 items-center ml-auto' onClick={() => { setShowForm(false) }}>Cancel</button>
          </div>
        </div>}
        {props.discountsArr.length === 0 && props.type === "Discount" && <div class="flex p-6 bg-white mt-4 items-center justify-center">
          <div className="text-gray-300"><span className='text-center'>No Discounts...</span> </div>
        </div>}
        {props.markupsArr.length === 0 && props.type === "Markup" && <div class="flex p-6 bg-white mt-4 items-center justify-center">
          <div className="text-gray-300"><span className='text-center'>No Duties Provision...</span> </div>
        </div>}
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={itemIds}
              strategy={verticalListSortingStrategy}
            >
              {itemIds.map((item) => {
                let discountItem = filterById(appliedDiscounts, item);
                if (discountItem.amount >= 0 && props.type === "Discount") {
                  return (
                    <DiscountItem setIsOpen={setIsOpen} handle={true} editDiscountHandler={editDiscountHandler} setDeleteDiscountId={setDeleteDiscountId} key={item} id={item} discountItem={discountItem} type={props.type} />
                  )
                }
                if (discountItem.amount <= 0 && props.type === "Markup") {
                  return (
                    <DiscountItem setIsOpen={setIsOpen} handle={true} editDiscountHandler={editDiscountHandler} setDeleteDiscountId={setDeleteDiscountId} key={item} id={item} discountItem={discountItem} type={props.type} />
                  )
                }
              })}
            </SortableContext>
          </DndContext>
        </div>


      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
        <div className='mb-6'>
          <p>Are you sure that you want to delete this item?</p>
        </div>
        <div className="flex items-center justify-between">
          <button className="btn" onClick={() => {
            setDeleteDiscountId('');
            setIsOpen(false);
          }}>Cancel</button>
          <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
            e.preventDefault();
            deleteDiscountHandler(deleteDiscountId);
            setIsOpen(false);
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>

            <span className='ml-2'>Yes Delete</span>
          </button>
        </div>
      </Modal>
    </>
  )
}

export default Discounts