import { React, useRef, useState, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Tooltip } from 'react-tooltip';

export default function DiscountItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const amountInput = useRef();
    const descInput = useRef();


    const [showForm, setShowForm] = useState(false);

    const [discountItem, setDiscountItem] = useState(props.discountItem);

    useEffect(() => {
        props.editDiscountHandler(discountItem);
    }, [discountItem]);

    return (
        <div ref={setNodeRef} style={style}>
            <div className="flex p-6 bg-white border border-gray-200 rounded-lg shadow mt-4 items-center justify-between">
                <button {...attributes} {...listeners} className="flex text-gray-500 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-[-17px]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                </button>
                <div className="text-gray-900 mr-auto ml-6"><span className='text-xl border-r border-gray-300 pr-2 mr-2'>{discountItem.amount >= 0 ? discountItem.amount : discountItem.amount * -1}%</span> <span>{discountItem.description}</span>
                </div>
                <div className='flex'>
                    <button data-tooltip-delay-show={300} className='btn p-2 mr-2 flex items-center tooltip-edit' onClick={(e) => {
                        e.preventDefault();
                        setShowForm(true);
                    }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                    </button>
                    <Tooltip anchorSelect=".tooltip-edit" place="top" className="tooltip">
                        Edit
                    </Tooltip>
                    <button data-tooltip-delay-show={300} className='btn p-2 bg-red-200 hover:bg-red-300 flex items-center tooltip-delete' onClick={(e) => {
                        e.preventDefault();
                        props.setDeleteDiscountId(props.id);
                        props.setIsOpen(true);
                    }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </button>
                    <Tooltip anchorSelect=".tooltip-delete" place="top" className="tooltip">
                        Delete
                    </Tooltip>
                </div>
            </div>
            {showForm && <div className='flex justify-between border-b border-slate-200 pb-6 pt-6 mb-6'>
                <div className='flex items-center w-[75%]'>
                    <div className="items-center w-full">
                        <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor="Description">
                            Description
                        </label>
                        <input required ref={descInput} defaultValue={discountItem.description} className='border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5' id="Description" type="text" placeholder="Description" />
                    </div>
                    <div className="items-center ml-2">
                        <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor="Amount">
                            Amount (%)
                        </label>
                        <input required ref={amountInput} defaultValue={discountItem.amount >= 0 ? discountItem.amount : discountItem.amount * -1} className='border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5' id="Amount" type="number" placeholder="0" step="0.01" />
                    </div>
                </div>
                <div className='flex items-end'>
                    <button className='btn flex items-center ml-auto mr-4' onClick={(e) => {
                        e.preventDefault();
                        setDiscountItem({
                            id: discountItem.id,
                            amount: props.type === "Markup" ? amountInput.current.value * -1 : amountInput.current.value,
                            description: descInput.current.value
                        });
                        setShowForm(false);
                    }}>Save</button>
                    <button className='btn flex bg-gray-200 hover:bg-gray-300 items-center ml-auto mr-4' onClick={() => { setShowForm(false) }}>Cancel</button>
                </div>
            </div>}
        </div>
    );
}