import { useEffect, useState } from 'react'
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Logo from "../images/logo.jpg";
import RobotoReg from "../fonts/Roboto-Regular.ttf";
import RobotoBold from "../fonts/Roboto-Bold.ttf";
import Airtable from 'airtable';

// const base = new Airtable({ apiKey: 'keySkKhYYw9xwFndj' }).base('appJMJZQe9QsAGMUD');

const base = new Airtable({ apiKey: 'keyS3ziQL3oKejw6f' }).base('appVoEEASqx7A8Skh');

function PDFFile(props) {

  const [items, setItems] = useState([]);


  useEffect(() => {
    base('6937 THM Order').select({ view: "Carvalho SHARED VIEW" }).eachPage((records, fetchNextPage) => {
      //console.log(records);
      setItems(records);
      fetchNextPage();
      //console.log("SS", supplierState);
    })
  }, []);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header} fixed>
          <View style={styles.headerAddress}>
            <Text style={[styles.headerAddressText, { fontWeight: "bold" }]}>RHA Furniture EU</Text>
            <Text style={styles.headerAddressText}>Rua Antonio Candido Pinto, Numero 37,</Text>
            <Text style={styles.headerAddressText}>4715-400 Braga, Portugal</Text>
          </View>
          <View style={styles.logo}><Image src={Logo} styles={styles.logoImage} /></View>
        </View>
        <View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Order No:</Text><Text>6937AC</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Ref:</Text><Text>THM Benjamin West</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Acc. No:</Text><Text>123456</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Date:</Text><Text>20/09/22</Text></View>
          <View style={styles.detailsRow}><Text style={{ fontSize: "8pt" }}>N.B the order number and reference must be quoted on all correspondence</Text></View>
        </View>
        <View style={styles.divider}></View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "50%", paddingRight: "10pt" }}>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>To:</Text></View>
            <View style={styles.detailsRow}><Text>{props.supplierName}</Text></View>
            <View style={styles.detailsRow}><Text>{props.supplierAddress}</Text></View>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>NIF:</Text><Text>{props.supplierTaxNumber}</Text></View>
          </View>
          <View style={{ width: "50%", paddingLeft: "10pt" }}>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "100%" }]}>Delivery to/work to be carried out at:</Text></View>
            <View>
              <Text>Ex-works</Text>
              <Text>Please contact collection with logistics@rhafurniture.com</Text>
            </View>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "50%", paddingRight: "10pt" }}>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>FAO:</Text><Text>Emília Carneiro</Text></View>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>Email:</Text><Text>info@veppe.pt</Text></View>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>Tel No:</Text><Text>924017194</Text></View>
          </View>
          <View style={{ width: "50%", paddingLeft: "10pt" }}>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Settlement Terms:</Text><Text>30 Days</Text></View>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Collection date:</Text><Text>Mid Decemeber 2022</Text></View>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Location on site:</Text><Text>info@veppe.pt</Text></View>
          </View>
        </View>
        <View style={styles.table}>
          {items.map((item, key) => {

            //const cellCount = Object.keys(item.fields).length;
            // const cellWidth = 100 / cellCount;
            // const cellWidthStr = cellWidth + "%";

            return (
              <View style={styles.row} key={key}>
                <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.fields.Quantity}</Text></View>
                <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.fields.Code}</Text></View>
                <View style={[styles.cell, { 'width': "30%" }]}><Text>{item.fields.Description}</Text></View>
                <View style={[styles.cell, { 'width': "30%" }]}><Image src={item.fields.Image[0].url} /></View>
                <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.fields['Unit Price']}</Text></View>
                <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.fields.Total}</Text></View>
              </View>
            )
          })}
        </View>
        <View style={styles.footer} fixed>
          <View style={{ width: "80%" }}>
            <Text>Registered Address: Rua Antonio Candido Pinto, Numero 37, 4715-400 Braga, Portugal</Text>
            <Text>Portuguese Company Registration Number: 6680-8875-8234</Text>
            <Text>Portuguese NIF Number: 516576755</Text>
          </View>
          <View style={{ width: "20%" }}>
            <Text style={{ textAlign: "right" }} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} />
          </View>
        </View>
      </Page>
    </Document>
  )
};


Font.register({
  family: 'Roboto', fonts: [
    { src: RobotoReg },
    { src: RobotoBold, fontWeight: 'bold' },
  ]
});

const styles = StyleSheet.create({
  body: {
    padding: "30pt",
    fontFamily: "Roboto",
    fontSize: "8pt",
    position: "relative",
    paddingBottom: "80pt",
    width: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "50pt"
  },
  headerAddress: {
    width: "35%"
  },
  headerAddressText: {
    marginBottom: "2pt"
  },
  logo: {
    width: "30%",
  },
  logoImage: {
    display: "block",
    width: "100%",
    height: "auto"
  },
  detailsRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "4pt"
  },
  detailLabel: {
    fontWeight: "bold",
    width: "60pt",
  },
  table: {
    width: "100%",
    marginTop: "10pt",
    borderTopWidth: "1px",
    borderTopColor: "#666",
    borderTopStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    boxSizing: "border-box",
  },
  cell: {
    padding: "10pt",
    borderLeftWidth: "1px",
    borderLeftColor: "#666",
    borderLeftStyle: "solid",
    boxSizing: "border-box"
  },
  divider: {
    paddingTop: "10pt",
    marginBottom: "10pt",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    boxSizing: "border-box",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: "30px",
    color: "#000",
    paddingVertical: 20,
    fontSize: "8pt",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxSizing: "border-box"
  }
});

//ReactPDF.render(<Quixote />);

export default PDFFile