import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import * as icons from "../images/imagesSvg"


const options = [
    { name: 'Select Status...', value: "", icon: icons.warnSvg, color: "red" },
    { name: 'N/A', value: "na", icon: icons.minusSvg, color: "gray" },
    { name: 'Complete', value: "complete", icon: icons.checkSvg, color: "green" },
    { name: 'In Progress', value: "inprogress", icon: icons.timeSvg, color: "yellow" },
]

export default function ListBox(props) {


  const [selected, setSelected] = useState(props.selectedIndex ? options[options.findIndex(object => {
    return object.value === props.selectedIndex;
  })]: options[0]);

  return (
    <div className="relative z-20 w-48 ">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className={`btn ${selected.color}  w-full flex justify-start items-center relative cursor-default rounded-md text-blue-900 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm`}>
            {selected.icon}
            <span className="ml-2 block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-blue-900"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                    }`
                  }
                  value={option}
                  onClick={() => {props.onSelectChangeHandler(option.value)}}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-900">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
