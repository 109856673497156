import axios from "axios";
import * as configuration from "../config"

export function getItems(access_token, product_id, itemType) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_product_components&view=product_components&apikey=${access_token}&product_id=${product_id}&itemtype=${itemType}`
    };

    return axios(config)
        .then(function (response) {
            console.log(itemType,response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

// export function getSuppliers(access_token) {

//     var config = {
//         method: 'get',
//         url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers`,
//         headers: {
//             'Authorization': `Bearer ${access_token}`
//         }
//     };

//     return axios(config)
//         .then(function (response) {
//             //console.log(response.data.data);
//             return response.data.data;
//         })
//         .catch(function (error) {
//             console.log(error);
//         });
// }

export function getSuppliers(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_suppliers&view=suppliers&apikey=${access_token}`
    };

    return axios(config)
        .then(function (response) {
            console.log("Suppliers:", response.data)
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getItem(access_token, id) {
    
    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/product_components/product_components/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            console.log("Item: ",response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}



export function addItem(access_token, data) {

    var config = {
        method: 'post',
        url: `${configuration.baseUrl}/api/index.php/v1/product_components/product_components`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

//
export function addItemImage(access_token, files) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i])
    }

    return axios.post(`${configuration.baseUrl}/index.php/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               //return console.log(JSON.stringify(response.data));
               return response.data;
            })
            .catch(function (error) {
                console.log(error);
                throw new Error(error.message);
            });

}
//

//
export function deleteItemImage(access_token, filename) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    formData.append("filename", filename)

    return axios.post(`${configuration.baseUrl}/index.php/delete`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               //return console.log(JSON.stringify(response.data));
               return response.data;
            })
            .catch(function (error) {
                console.log(error);
                throw new Error(error.message);
            });

}
//

export function editItem(access_token, fabricData, id) {

    var data = JSON.stringify(fabricData);

    var config = {
        method: 'patch',
        url: `${configuration.baseUrl}/api/index.php/v1/product_components/product_components/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });

}

export function deleteItem(access_token, id) {
    
    var config = {
        method: 'delete',
        url: `${configuration.baseUrl}/api/index.php/v1/product_components/product_components/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return JSON.stringify(response.data);
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });
}

//
export function getImageBuffer(access_token, files) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    formData.append("files", files)

    return axios.post(`${configuration.baseUrl}/index.php/imagebuffer`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               return JSON.parse(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

}
//


export function orderItems(access_token, items_order) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_product_components&view=order&apikey=${access_token}&items_order=${items_order}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            //return error.response.data;
            throw new Error(error.message);
        });

}