import axios from "axios";
import * as configuration from "../config";

export function getSuppliers(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_suppliers&view=suppliers&apikey=${access_token}`
    };

    return axios(config)
        .then(function (response) {
            console.log("Suppliers:", response.data)
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getSuppliersCategories(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/categories`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getSupplier(access_token, id) {
    
    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}



export function addSupplier(access_token, supplierData) {

    var data = JSON.stringify(supplierData);

    var config = {
        method: 'post',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            //console.log(error);
            throw new Error(error.response.data.errors[0].title);
        });

}

export function editSupplier(access_token, supplierData, id) {

    var data = JSON.stringify(supplierData);

    var config = {
        method: 'patch',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            //return JSON.stringify(response.data);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });

}

export function deleteSupplier(access_token, id) {

    var config = {
        method: 'delete',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            //return response.data.data;
            return JSON.stringify(response.data);
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });
}

export function orderSuppliers(access_token, items_order) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_suppliers&view=order&apikey=${access_token}&items_order=${items_order}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            //return error.response.data;
            throw new Error(error.message);
        });

}
