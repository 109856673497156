import { useState, Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function Toast(props) {

    function closeModal() {
        props.setIsOpen(false)
    }

    function openModal() {
        props.setIsOpen(true)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            props.setIsOpen(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [props.isOpen]);


    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog as="div" className="absolute top-0 flex justify-center w-full z-10" onClose={closeModal}>
                <div className="flex justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300 mt-[-2rem]"
                        enterFrom="opacity-0  mt-[-2rem]"
                        enterTo="opacity-100  mt-0"
                        leave="ease-in duration-200 mt-0"
                        leaveFrom="opacity-100 mt-0"
                        leaveTo="opacity-0 mt-[-2rem]"
                    >
                        <Dialog.Panel className={`${props.toastType === "error" ? "bg-red-100" : "bg-green-100"} min-w-[200px] transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all`}>
                            <button onClick={closeModal} className='absolute right-3 top-3'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg></button>
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                {props.toastTitle}
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {props.toastText}
                                </p>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>

            </Dialog>
        </Transition>
    )
}

export default Toast