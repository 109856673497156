import axios from "axios";
import * as configuration from "../config";


export function getCustomers(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_customers&view=customers&apikey=${access_token}`
    };

    return axios(config)
        .then(function (response) {
            console.log("Customers:", response.data.data)
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getCustomersCategories(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/customers/categories`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getCustomer(access_token, id) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/customers/customers/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            console.log("Customer: ",response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}



export function addCustomer(access_token, customerData) {

    var data = JSON.stringify(customerData);

    var config = {
        method: 'post',
        url: `${configuration.baseUrl}/api/index.php/v1/customers/customers`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

export function editCustomer(access_token, customerData, id) {

    var data = JSON.stringify(customerData);

    var config = {
        method: 'patch',
        url: `${configuration.baseUrl}/api/index.php/v1/customers/customers/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //return JSON.stringify(response.data);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

export function deleteCustomer(access_token, id) {

    var config = {
        method: 'delete',
        url: `${configuration.baseUrl}/api/index.php/v1/customers/customers/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //return response.data.data;
            return JSON.stringify(response.data);
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });
}

export function orderCustomers(access_token, items_order) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_customers&view=order&apikey=${access_token}&items_order=${items_order}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            //return error.response.data;
            throw new Error(error.message);
        });

}