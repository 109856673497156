import { useState, Fragment, useEffect } from 'react'
import * as api from '../api/LoginApi'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Toast from '../components/Toast';
import Logo from "../images/RHA-admin-logo.svg";


function Login() {
    const navigate = useNavigate();


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [toastType, setToastType] = useState("error");

    const submitHandler = (e) => {
        e.preventDefault();
        api.login(username, password).then((resp) => {
            if (resp) {
                return resp;
            } else {
                throw new Error("Invalid user credentials");
            }
        }).then((resp) => {
            return api.getUser(resp.apikey, resp.userid).then((resolve, reject) => {
                if(resolve){
                    sessionStorage.setItem("apikey", resp.apikey);
                    sessionStorage.setItem("userid", resp.userid);
                }
            })
        }).then(()=>{
            navigate('/projects');
        }).catch((error) => {
            setToastTitle(error.name);
            setToastText(error.message);
            setIsOpen(true);
        });
    }

    return (
        <>
            <div className='absolute bg-slate-700 top-0 left-0 right-0 bottom-0 z-0'></div>
            <div className='flex flex-col items-center z-10 relative'>
                <div className="w-full max-w-sm mt-[15vh]">
                <div className='text-white font-bold p-6 text-2xl mb-6'><img className='m-auto w-[60%]' src={Logo} alt="RHA Logo" /></div>
                    <form className="bg-white shadow-md rounded-xl p-12 mb-4" onSubmit={submitHandler}>
                        <h2 className='font-bold text-2xl mb-4 text-center text-slate-700'>Login</h2>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                Name
                            </label>
                            <input className="input" id="username" type="text" placeholder="Username" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                Password
                            </label>
                            <input className="input" id="password" type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                        </div>
                        <div className="flex items-center justify-between">
                            <button className="btn" type="submit">
                                Sign In
                            </button>
                            {/* <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                                Forgot Password?
                            </a> */}
                        </div>
                    </form>
                </div>
            </div>
            <Toast toastType={toastType} isOpen={isOpen} setIsOpen={setIsOpen} toastTitle={toastTitle} toastText={toastText} />


        </>
    )
}

export default Login