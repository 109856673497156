import React, { useState, useContext, useEffect, useCallback } from 'react'
import * as api from '../../api/QuotesApi';
import { QuotesContext } from '../../pages/Quotes'
import FormElement from '../FormElement';
import Dropzone from '../Dropzone';

function AddQuoteForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const { refresh, setRefresh, isOpen, setIsOpen } = useContext(QuotesContext);
    const [quoteSuppliers, setQuoteSuppliers] = useState({});

    const [quoteData, setQuoteData] = useState({
        "name": '',
        //"description": '',
        "project_id": props.project_id,
        //"files": '',
        "created_by": userid,
        "modified_by": userid,
    });

    useEffect(() => {
        api.getQuotesSuppliers(apikey).then((resp) => {
            let quoteSupplierData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    quoteSupplierData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'value': item.attributes.name,
                        }
                    )
                }
            });
            setQuoteSuppliers(quoteSupplierData);
        });
    }, []);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        api.addQuote(apikey, quoteData).then((resp) => {
            setRefresh(!refresh);
            setIsOpen(false);
        });
    }

    const fileUploadHandler = (data) => {
        let imagesArr = [];
        data.map((item)=>{
            imagesArr.push(item.name);
        })
        
        api.addQuoteImage(apikey,data).then((resp)=>{
            setQuoteData((prevState) => {
                return {
                    ...prevState,
                    files: imagesArr.join()
                }
            });
            
        });
    }

    const FormElements = [
        { elementLabel: "Name", elementName: "name", elementType: "text", className: "w-[49%]" },
        // { elementLabel: "Project ID", elementName: "project_id", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    ]

    return (
        <div className="w-full">
            <form onSubmit={onSubmitHandler} className="flex w-full flex-wrap justify-between">
                {FormElements.map((item, index) => (
                    <div key={index} className={item.className ? item.className : 'w-full'}>
                        <FormElement
                            elementLabel={item.elementLabel}
                            elementName={item.elementName}
                            elementType={item.elementType}
                            value={quoteData[item.elementName]}
                            elementOptions={quoteSuppliers}
                            onChangeHandler={(e) => {
                                setQuoteData((prevState) => {
                                    return {
                                        ...prevState,
                                        [item.elementName]: e.target.value
                                    }
                                })
                            }} />
                    </div>
                ))}
                {/* <Dropzone fileUploadHandler={fileUploadHandler} /> */}
                <div className='w-full mb-8 pt-8 border-b border-gray-200'></div>
                <div className="flex items-center justify-between">
                    <button className="btn flex items-center" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                        </svg>
                        <span className='ml-2'>Save</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddQuoteForm