import { useEffect, useMemo, useState, createContext } from 'react'
import * as api from '../api/CustomersApi'
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import Table from '../components/Table/Table';
import CustomerForm from '../components/Customers/CustomerForm';
import Loader from '../components/Loader';
import Sidebar from '../components/Sidebar';
import ContentFrame from '../components/ContentFrame';
import * as svg from "../images/imagesSvg";
import Toast from '../components/Toast';
import _ from "lodash";

export const CustomersContext = createContext();


function Customers() {

  const apikey = sessionStorage.getItem('apikey');
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [apiFinished, setApiFinished] = useState(false);

  const [customerId, setCustomerId] = useState('');

  const [selectedModal, setSelectedModal] = useState('');

  const [currentPage, setCurrentPage] = useState(0);

  const [sortedIds, setSortedIds] = useState();

  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [toastType, setToastType] = useState("");


  const navigate = useNavigate();

  const doToast = (type, title, message, doRefresh) => {
    if (doRefresh) {
      setRefresh(!refresh);
    }
    setToastType(type);
    setToastTitle(title);
    setToastText(message);
    setIsOpenToast(true);
  }

  const openEditView = (itemId) => {
    console.log(itemId);
    if (itemId !== '') {
      setSelectedModal("edit");
      setCustomerId(itemId);
      setIsOpen(true);
    }
  }

  const onDeleteHandler = (itemId) => {
    if (itemId !== '') { 
      setApiFinished(false);
      api.deleteCustomer(apikey, itemId).then((resp) => {
        doToast("success", "Success", "Entry deleted", true);        
      }).catch((resp) => {
        doToast("error", "Error", resp.message);    
      });
    }
  }

  const onDuplicateHandler = (itemId) => {
    setApiFinished(false);
    api.getCustomer(apikey, itemId).then((resp) => {
      let customerData = resp.attributes;
      customerData.id = "";
      api.addCustomer(apikey, customerData).then((resp) => {
        setCustomers((prevState) => [...prevState, resp]);
        doToast("success", "Success", "Entry saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    })
  }

  const reOrderHandler = () => {
    let items_order = _.map(sortedIds, 'id').toString();
    api.orderCustomers(apikey, items_order).then(() => {
      doToast("success", "Success", "Items reordered", true);
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }

  useEffect(() => {
    if (apikey) {
      api.getCustomers(apikey)
        .then((res) => {
          if (res) {
            setCustomers(res);
            setApiFinished(true);
          }
        });
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setCustomerId("");
    if (apikey) {
      api.getCustomers(apikey)
        .then((res) => {
          if (res) {
            setCustomers(res);
            setApiFinished(true);
          }
        });
    }
  }, [refresh]);

  useEffect(() => {
    let customerData = [];
    customers.map((item, index) => {
      if (item.attributes.state === 1) {
        customerData.push(
          {
            'id': item.attributes.id,
            'name': item.attributes.name,
            'contactname': item.attributes.contactname,
            'code': item.attributes.code,
          }
        )
      }
    });
    setData(customerData);
  }, [customers])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Contact Name',
        accessor: 'contactname',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
    ],
    []
  )

  const addCustomerHandler = () => {
    setSelectedModal('add');
    setIsOpen(true);
  }

  return (
    <>
      {apikey &&
        <CustomersContext.Provider value={{ refresh, setRefresh, isOpen, setIsOpen }}>
          {!apiFinished && <Loader />}
          {customers && apiFinished &&
            <>
              <div className='flex h-full'>
                <Sidebar activeItem={"customers"} />
                <ContentFrame>
                  <div className='flex p-6 w-full justify-between items-center'><div><h2 className='text-slate-700 font-bold text-2xl'>Customers</h2></div>
                    <button className='btn flex items-center ml-auto mr-4' onClick={addCustomerHandler}>
                      {svg.addSvg}
                      <span className='ml-2'>Add Customer</span>
                    </button>
                    <button className='btn flex items-center mr-4' onClick={reOrderHandler}>
                      {svg.reOrderSvg}
                      <span className='ml-2'>Save Items Ordering</span>
                    </button>
                  </div>

                  <Table columns={columns} data={data} setSortedIds={setSortedIds} setData={setData} openEditView={openEditView} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} currentPage={currentPage} setCurrentPage={setCurrentPage} tableType="customers" />

                </ContentFrame>
              </div>
            </>
          }
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} setRefresh={setRefresh} refresh={refresh} dialogTitle={selectedModal == 'edit' ? 'Edit Customer' : 'Add Customer'} modalSize={"max-w-3xl"}>
            <CustomerForm customerId={customerId} selectedModal={selectedModal} setSelectedModal={setSelectedModal} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} />
          </Modal>
          <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </CustomersContext.Provider>
      }
    </>
  )
}

export default Customers