import React, { useState, useEffect } from 'react'
import { useTable, useSortBy, useRowSelect, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import Modal from './Modal';
import { Link } from 'react-router-dom';
import * as svg from '../images/imagesSvg';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    tableType
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)


    return (
        <div className={`absolute flex items-center top-[-66px] right-[200px] 
        ${tableType === 'products' ? 'right-[355px]' : ''}
        ${tableType === 'fabrics' ? 'top-[-75px] right-[150px]' : ''}`
        }>
            <span className={`mr-2 uppercase text-xs text-gray-700 font-bold`}>Search:{' '}</span>
            <input className='input inline w-auto min-w-[250px]'
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </div>
    )
}

function Table({ columns, data, setEditSelect, setDeleteSelect, currentPage, setCurrentPage, tableType, hiddenColumns, onDeleteHandler, openEditView }) {

    const [isOpen, setIsOpen] = useState(false);
    const [itemId, setItemId] = useState('');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setPagination,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
        state,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: currentPage,
                hiddenColumns: hiddenColumns ? hiddenColumns : []
            },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div className='pl-6'>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div className='pl-6'>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
                {
                    id: 'edit',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            Actions
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div className='flex'>
                            <button className='btn p-2 mr-2 flex items-center' onClick={(e) => {
                                e.preventDefault();
                                openEditView(row.values.id);
                            }}>
                                {svg.editSvg}

                            </button>
                            {tableType == "Projects" &&
                                <Link to='/products' state={
                                    {
                                        "project_id": row.values.id,
                                        "project_name": row.values.project_name,
                                        "project_description": row.values.project_description,
                                        "customer": row.values.customer,
                                        "customer_id": row.values.customer_id,
                                        "project_number": row.values.project_number,
                                        "files": row.values.files,
                                    }
                                } className={`btn p-2 mr-2 flex items-center`}>
                                    {svg.notePadSvg}
                                    <span className='ml-2'>Quotes</span></Link>
                            }
                            <button className='btn p-2 bg-red-200 hover:bg-red-300 flex items-center' onClick={(e) => {
                                e.preventDefault();
                                setItemId(row.values.id);
                                setIsOpen(true);
                            }}>
                                {svg.trashSvg}
                            </button>
                        </div>
                    ),
                },
            ])
        }
    )
    return (
        <>
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            pageIndex,
                            pageSize,
                            pageCount,
                            canNextPage,
                            canPreviousPage,
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}
            <div className='relative'>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    tableType = {tableType}
                />
                <div className='overflow-scroll w-full'>
                    <table {...getTableProps()} className="w-full text-sm text-left text-gray-500">
                        <thead className='text-xs text-gray-700 uppercase bg-slate-100'>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className='py-6 px-4'>
                                            <div className='flex items-center'>
                                                <span>{column.render('Header')}</span>
                                                <span className='ml-2'>
                                                    {column.isSorted ? (column.isSortedDesc ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                    </svg>
                                                        : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg>
                                                    ) : ''}
                                                </span>
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="bg-white">
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} className="table_row">
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className='p-4'><div className='w-full max-h-24 block overflow-auto'>{cell.render('Cell')}</div></td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination flex justify-end py-2 px-6 items-center text-sm text-gray-700">
                    <button className='p-2' onClick={() => {
                        gotoPage(0);
                        setCurrentPage(0);
                    }
                    } disabled={!canPreviousPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <button className='p-2' onClick={() => {
                        previousPage();
                        setCurrentPage(pageIndex - 1);
                    }} disabled={!canPreviousPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <button className='p-2' onClick={() => {
                        nextPage();
                        setCurrentPage(pageIndex + 1);
                    }} disabled={!canNextPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <button className='p-2' onClick={() => {
                        gotoPage(pageCount - 1);
                        setCurrentPage(pageCount - 1);
                    }} disabled={!canNextPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <span className='p-2'>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span className='p-2'>
                        Go to page:{' '}
                        <input
                            className='py-1 px-2 w-20 border rounded'
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    {/* <pre>
                    <code>
                        {JSON.stringify(
                            {
                                selectedRowIds: selectedRowIds,
                                'selectedFlatRows[].original': selectedFlatRows.map(
                                    d => d.original
                                ),
                            },
                            null,
                            2
                        )}
                    </code>
                </pre> */}
                </div>
            </div>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        //setDeleteSelect(itemId)
                        onDeleteHandler(itemId);
                        setIsOpen(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default Table