import { useEffect, useMemo, useState, createContext } from 'react';
import { useParams } from 'react-router-dom';
import * as api from '../api/ProjectsApi'
import { addProduct, getProducts } from '../api/ProductsApi';
import { addProductComponent, getProductComponents } from '../api/ProductComponentsApi';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import Table from '../components/Table/Table';
import Loader from '../components/Loader';
import Sidebar from '../components/Sidebar';
import * as configuration from "../config";
import NoImage from "../images/RHA-admin-no-image.svg";
import ContentFrame from '../components/ContentFrame';
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import * as svg from "../images/imagesSvg";
import ProjectForm from '../components/Projects/ProjectForm';

import Toast from '../components/Toast';

export const ProjectsContext = createContext();

const Image = (props) => {
  try {
    const imagesArr = props.files.split(",");
    if (!Array.isArray(imagesArr)) {
      throw new Error("is not array");
    }
    return (
      <div className='w-[100px]'><img src={imagesArr[0] != "" ? `${configuration.baseUrl}/images/${imagesArr[0]}` : NoImage}
        onError={e => {
          e.currentTarget.src = NoImage;
        }}
      /></div>
    )
  } catch (e) {
    //console.log(e.message);
    return <></>;
  }
}


function Projects() {

  const apikey = sessionStorage.getItem('apikey');
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [apiFinished, setApiFinished] = useState(false);


  const [projectId, setProjectId] = useState('');

  const [selectedModal, setSelectedModal] = useState('');

  const [currentPage, setCurrentPage] = useState(0);

  const [sortedIds, setSortedIds] = useState();

  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [toastType, setToastType] = useState("");

  const [projectType, setProjectType] = useState("");

  const params = useParams();

  useEffect(() => {
    if (params.projectType) {
      setProjectType(params.projectType);
    } else {
      setProjectType("");
    }
  }, [params])


  const navigate = useNavigate();

  const doToast = (type, title, message, doRefresh) => {
    if (doRefresh) {
      setRefresh(!refresh);
    }
    setToastType(type);
    setToastTitle(title);
    setToastText(message);
    setIsOpenToast(true);
  }

  const openEditView = (itemId) => {
    console.log(itemId);
    if (itemId !== '') {
      setSelectedModal("edit");
      setProjectId(itemId);
      setIsOpen(true);
    }
  }

  const onDeleteHandler = (itemId) => {
    if (itemId !== '') {
      setApiFinished(false);
      api.deleteProject(apikey, itemId).then((resp) => {
        doToast("success", "Success", "Entry deleted", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }

  const onDuplicateHandler = (itemId) => {
    if (itemId !== '') {
      setApiFinished(false);
      api.duplicateProject(apikey, itemId).then((resp) => {
        doToast("success", "Success", "Entry duplicated", true);
        api.getProjects(apikey).then((resp) => {
          setProjects(resp);
        });
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }



  const reOrderHandler = () => {
    let items_order = _.map(sortedIds, 'id').toString();
    api.orderProjects(apikey, items_order).then(() => {
      doToast("success", "Success", "Items reordered", true);
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }



  useEffect(() => {
    if (apikey) {
      api.getProjects(apikey)
        .then((res) => {
          if (res) {
            setProjects(res);
            setApiFinished(true);
          }
        });
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setProjectId("");
    if (apikey) {
      api.getProjects(apikey)
        .then((res) => {
          if (res) {
            setProjects(res);
            setApiFinished(true);
          } else {
            setProjects([]);
            setApiFinished(true)
          }
        });
    }
  }, [refresh]);


  useEffect(() => {
    let projectData = [];
    projects.map((item, index) => {
      console.log("Resp: ", item.attributes.state);
      if (item.attributes.state === 1 && item.attributes.project_type === projectType) {
        projectData.push(
          {
            'id': item.attributes.id,
            'project_number': item.attributes.project_number,
            'order_number': item.attributes.order_number,
            'project_name': item.attributes.project_name,
            'revision_number': item.attributes.revision_number,
            'project_description': item.attributes.project_description,
            'customer': item.attributes.customer,
            'customer_id': item.attributes.customer_id,
            'image': <Image files={item.attributes.files} />,
            'files': item.attributes.files,
            'total': item.attributes.total === "Pending Save" ? <><Link to={`/products/${item.attributes.id}`} state={{ projectType: projectType }} className={`btn p-2 mr-2 flex items-center tooltip-savepending`}>Save Items</Link>
              <Tooltip anchorSelect=".tooltip-savepending" place="top" className="tooltip">
                Go to Project Items to Resave Total
              </Tooltip></> : configuration.UKPriceFormat(item.attributes.total),
          }
        )
      }
    });
    setData(projectData);
  }, [projects, projectType])

  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'image',
      },
      {
        Header: `${projectType === "order" ? "Order" : "Quote"} Number`,
        accessor: `${projectType === "order" ? "order_number" : "project_number"}`,
      },
      {
        Header: 'Project Name',
        accessor: 'project_name',
      },
      {
        Header: 'Revision',
        accessor: 'revision_number',
      },
      {
        Header: 'Description',
        accessor: 'project_description',
        Cell: ({ value, row }) => (
          <div dangerouslySetInnerHTML={{ __html: value }}></div>
        ),
      },
      {
        Header: 'Customer',
        accessor: 'customer',
      },
      {
        Header: 'Customer ID',
        accessor: 'customer_id',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Files',
        accessor: 'files',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    [projectType]
  )

  const addProjectHandler = () => {
    setSelectedModal('add');
    setIsOpen(true);
  }

  return (
    <>
      {apikey &&
        <ProjectsContext.Provider value={{ refresh, setRefresh, isOpen, setIsOpen }}>
          {!apiFinished && <Loader />}
          {projects && apiFinished &&
            <>
              <div className='flex h-full'>
                <Sidebar activeItem={projectType === "order" ? "orders" : "projects"} />
                <ContentFrame>
                  <div className='flex p-6 w-full justify-between items-center'><div><h2 className='text-slate-700 font-bold text-2xl'>{projectType === "order" ? "Orders" : "Quotes"}</h2></div>
                    <button className='btn flex items-center ml-auto mr-4' onClick={addProjectHandler}>
                      {svg.addSvg}
                      <span className='ml-2'>Add {projectType === "order" ? "Order" : "Quote"}</span>
                    </button>
                    <button className='btn flex items-center mr-4' onClick={reOrderHandler}>
                      {svg.reOrderSvg}
                      <span className='ml-2'>Save Items Ordering</span>
                    </button>
                  </div>
                  <Table columns={columns} data={data} setSortedIds={setSortedIds} setData={setData} openEditView={openEditView} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} currentPage={currentPage} setCurrentPage={setCurrentPage} tableType="projects" projectType={projectType} hiddenColumns={['files', 'image', 'customer_id']} />
                </ContentFrame>
              </div>
            </>
          }
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} setRefresh={setRefresh} refresh={refresh} dialogTitle={selectedModal == 'edit' ? `Edit ${projectType === "order" ? "Order" : "Quote"}` : `Add ${projectType === "order" ? "Order" : "Quote"}`} modalSize={"max-w-4xl"}>
            <ProjectForm projectType={projectType} projectId={projectId} selectedModal={selectedModal} setSelectedModal={setSelectedModal} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} />
          </Modal>
          <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </ProjectsContext.Provider>
      }
    </>
  )
}

export default Projects