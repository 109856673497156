import axios from "axios";
import * as configuration from "../config"

export function getProjects(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_projects&view=projects&apikey=${access_token}`
    };

    return axios(config)
        .then(function (response) {
            console.log("Projects:", response.data.data)
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

// export function getProjectsCustomers(access_token) {

//     var config = {
//         method: 'get',
//         url: `${configuration.baseUrl}/api/index.php/v1/customers/customers`,
//         headers: {
//             'Authorization': `Bearer ${access_token}`
//         }
//     };

//     return axios(config)
//         .then(function (response) {
//             //console.log(response.data.data);
//             return response.data.data;
//         })
//         .catch(function (error) {
//             console.log(error);
//         });
// }

export function getProjectsCustomers(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_customers&view=customers&apikey=${access_token}`
    };

    return axios(config)
        .then(function (response) {
            console.log("Customers from Project Form:", response.data.data)
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getProject(access_token, id) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/projects/projects/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}



export function addProject(access_token, projectData) {

    var data = projectData;

    var config = {
        method: 'post',
        url: `${configuration.baseUrl}/api/index.php/v1/projects/projects`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log("ERRRR: ", error.response.data);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

//
export function addProjectImage(access_token, files) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i])
    }

    return axios.post(`${configuration.baseUrl}/index.php/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
        //return console.log(JSON.stringify(response.data));
        return response.data;
    })
        .catch(function (error) {
            console.log(error);
            throw new Error(error.message);
        });

}
//

//
export function deleteProjectImage(access_token, filename) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    formData.append("filename", filename)

    return axios.post(`${configuration.baseUrl}/index.php/delete`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
        .then(function (response) {
            //return console.log(JSON.stringify(response.data));
            console.log(response.data);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            throw new Error(error.message);
        });

}
//

export function editProject(access_token, projectData, id) {

    var data = JSON.stringify(projectData);

    var config = {
        method: 'patch',
        url: `${configuration.baseUrl}/api/index.php/v1/projects/projects/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            //return JSON.stringify(response.data);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });

}

export function deleteProject(access_token, project_id) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_projects&view=delete&apikey=${access_token}&project_id=${project_id}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log("ERRRR: ", error.response.data);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

export function duplicateProject(access_token, project_id) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_projects&view=duplicate&apikey=${access_token}&project_id=${project_id}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log("ERRRR: ", error.response.data);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

export function getConfig(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php/config?apikey=${access_token}`
    };

    return axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function orderProjects(access_token, items_order) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_projects&view=order&apikey=${access_token}&items_order=${items_order}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            //return error.response.data;
            throw new Error(error.message);
        });

}


