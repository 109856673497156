import React, { useEffect, forwardRef, useState } from 'react';
import * as configuration from "../config";
import NoImage from "../images/RHA-admin-no-image.svg";
import Modal from './Modal';
import { Document } from '@react-pdf/renderer';
import { Editor } from '@tinymce/tinymce-react';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    MouseSensor
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    horizontalListSortingStrategy,
    rectSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';


const Image = (props) => {

    const [imageExists, setImageExists] = useState(true);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };


    return (
        <div className='w-[16%] flex items-center justify-center' ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className='relative p-2'>
                {props.item.split('.').reverse()[0] == "pdf" &&
                    <button className='btn h-full w-full flex items-center flex-col' onClick={(e) => {
                        e.preventDefault();
                        props.setSelectedImage(props.item);
                        props.setIsOpen(true)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        <h3 className='mt-2'>{props.item}</h3>
                    </button>
                }
                {props.item.split('.').reverse()[0] != "pdf" &&
                    <img className='hover:cursor-pointer hover:opacity-80 m-auto w-full h-auto'
                        onClick={() => {
                            props.setSelectedImage(props.item);
                            if (imageExists) {
                                props.setIsOpen(true)
                            }
                            else {
                                props.removeImageHandler(props.item)
                            }
                        }
                        }
                        src={`${configuration.baseUrl}/images/${props.item}`}

                        onError={e => {
                            e.currentTarget.src = NoImage;
                            setImageExists(false);
                        }} />
                }
            </div>
        </div>
    )
}

const Images = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedImage, setSelectedImage] = useState(false);
    const [imagesArray, setImagesArray] = useState([]);


    const sensors = useSensors(
        // useSensor(PointerSensor),
        // useSensor(KeyboardSensor, {
        //     coordinateGetter: sortableKeyboardCoordinates,
        // }),
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        })
    );


    useEffect(() => {
        //console.log(props.value);
        setImagesArray(props.value ? props.value.split(",") : []);
    }, [props.value]);



    const deleteImageHandler = (selectedImage) => {
        const index = imagesArray.indexOf(selectedImage);
        if (index > -1) { // only splice array when item is found
            imagesArray.splice(index, 1); // 2nd parameter means remove one item only
            props.fileDeleteHandler(imagesArray, selectedImage);
        }
    }

    const removeImageHandler = (selectedImage) => {
        const index = imagesArray.indexOf(selectedImage);
        if (index > -1) { // only splice array when item is found
            imagesArray.splice(index, 1); // 2nd parameter means remove one item only
            props.fileRemoveHandler(imagesArray);
        }
    }



    return (
        <>

            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={imagesArray}
                    strategy={rectSortingStrategy}
                >
                    {imagesArray.length > 0 && imagesArray.length > 0 && imagesArray.map((item, index) => (
                        item != "" &&
                        <Image key={index} id={item} item={item} setSelectedImage={setSelectedImage} setIsOpen={setIsOpen} removeImageHandler={removeImageHandler} />
                    ))}
                </SortableContext>
            </DndContext>

            <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalSize={"max-w-2xl"} >
                {selectedImage && selectedImage.split('.').reverse()[0] == "pdf" &&
                    <div className='mb-6'>
                        <button className='btn h-[400px] w-full flex items-center flex-col' onClick={(e) => {
                            e.preventDefault();
                            window.open(`${configuration.baseUrl}/images/${selectedImage}`, '_blank', 'noopener,noreferrer');
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            <h3 className='mt-2'>Open <span className='underline'>{selectedImage}</span> in a new tab</h3>
                        </button>
                    </div>
                }
                {selectedImage && selectedImage.split('.').reverse()[0] != "pdf" &&
                    <div className='mb-6'>
                        <img className='m-auto' src={`${configuration.baseUrl}/images/${selectedImage}`} />
                    </div>
                }
                <div className="flex items-center justify-between">
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center ml-auto" onClick={() => {

                        setIsOpen2(true);

                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Delete</span>
                    </button>
                </div>
            </Modal >
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        deleteImageHandler(selectedImage);
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
        </>
    )

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setImagesArray((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                let newArr = arrayMove(items, oldIndex, newIndex);
                props.fileReorderHandler(newArr);
                return newArr;
            });
        }
    }
}


const FormElement = forwardRef((props, ref) => {

    const [value, setValue] = useState('');
    //useEffect(() => setValue(initialValue ?? ''), [initialValue]);

    if (props.elementType == "element") {
        return (
            <div></div>
        )
    } else if (props.elementType == "title") {
        return (
            <h2>{props.elementLabel}</h2>
        )
    } else if (props.elementType == "note") {
        return (
            <p className='block text-gray-500 text-xs uppercase font-bold mb-4 mt-1'>{props.elementLabel}</p>
        )
    } else if (props.elementType == "button") {
        return (
            <button className='btn bg-gray-200 hover:bg-gray-300 text-gray-800 py-2.5' onClick={props.elementAction}>{props.elementLabel}</button>
        )
    }
    else if (props.elementType == "select") {
        return (
            <div className="mb-4 items-center">
                <label className="block text-gray-500 text-xs font-bold uppercase mb-1" htmlFor={props.elementName}>
                    {props.elementLabel}
                </label>
                <select className='appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5' value={props.elementValue} id={props.elementName} type={props.elementType} onChange={props.onChangeHandler}>
                    <option data-id="" value="">Please Select...</option>
                    {props.elementOptions.length && props.elementOptions.map((item) => {
                        return <option key={item.id} data-id={item.id} data-value={item.dataValue} value={item.value}>{item.name}</option>
                    })}
                </select>
            </div>
        )
    } else if (props.elementType == "textarea") {
        return (
            <>
                {/* <div className="mb-4 items-center">
                    <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor={props.elementName}>
                        {props.elementLabel}
                    </label>
                    <textarea id={props.elementName} value={value} onChange={props.onChangeHandler} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-52"></textarea>
                </div> */}

                <div className="mb-4 items-center">
                    <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor={props.elementName}>
                        {props.elementLabel}
                    </label>
                    <Editor
                        id={props.elementName}
                        apiKey='uxg809ofp1lg8yyvydu09o13p8n37npg5hk20gb55qhf1pn4'
                        initialValue={props.elementValue}
                        value={props.editorValue}
                        onEditorChange={props.onEditorChange}
                        init={{
                            menubar: false,
                            toolbar: 'bold italic forecolor',
                            valid_elements: 'strong,p,br,em,span[style]',
                            valid_styles: {
                                '*': 'color'
                              }
                        }}

                    />
                </div>
            </>
        )
    } else if (props.elementType == "files") {
        return (
            <div className="mb-4 items-center">
                <label className="block text-gray-500 text-xs font-bold w-48 uppercase mb-1" htmlFor={props.elementName}>
                    {props.elementLabel}
                </label>
                <div className='flex flex-wrap flex-row'><Images value={props.elementValue} fileDeleteHandler={props.fileDeleteHandler} fileRemoveHandler={props.fileRemoveHandler} fileReorderHandler={props.fileReorderHandler} /></div>
            </div>
        )
    } else if (props.elementType == "checkbox") {
        return (
            <div className="mb-0 items-center inline-block align-middle">
                <label className="text-gray-500 text-xs font-bold uppercase align-middle" htmlFor={props.elementName}>
                    {props.elementLabel}
                </label>
                <span className='ml-2 inline-block relative align-middle mt-[3px]'><input type="checkbox" id={props.elementName} value={props.elementValue} onChange={props.onChangeHandler} checked={props.elementValue !== 0 ? true : false} /></span>
            </div>
        )
    } else if (props.elementType == "number") {
        return (
            <div className="mb-4 items-center">
                <label className="block text-gray-500 text-xs font-bold uppercase mb-1" htmlFor={props.elementName}>
                    {props.elementLabel}
                </label>
                <input ref={ref} className={`${props.disabled ? "bg-gray-200 text-gray-500" : "bg-gray-50 text-gray-900"} border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={props.elementValue} id={props.elementName} type="number" step="0.01" min="0" placeholder={props.elementLabel} onChange={props.onChangeHandler} disabled={props.disabled ? true : false} />
            </div>
        )
    }
    else {
        return (
            <div className="mb-4 items-center">
                <label className="block text-gray-500 text-xs font-bold uppercase mb-1" htmlFor={props.elementName}>
                    {props.elementLabel}
                </label>
                <input onBlur={props.onBlurHandler} required={props.required} ref={ref} className={`${props.disabled ? "bg-gray-200 text-gray-500" : "bg-gray-50 text-gray-900"} border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} value={props.elementValue} id={props.elementName} type={props.elementType} placeholder={props.elementLabel} onChange={props.onChangeHandler} disabled={props.disabled ? true : false} />
            </div>
        )
    }
})

export default FormElement