import React, { useEffect, useMemo, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useTable, useSortBy, useRowSelect, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { DraggableTableRow } from "./DraggableTableRow";
import { StaticTableRow } from "./StaticTableRow";
import Modal from '../Modal';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import _, { indexOf } from 'lodash';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    tableType
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)


    return (
        <div className={`absolute flex items-center top-[-66px] 
        ${tableType === 'projects' ? 'right-[416px]' : ''}
        ${tableType === 'products' ? 'right-[587px]' : ''}
        ${tableType === 'extras' ? 'right-[417px]' : ''}
        ${tableType === 'suppliers' ? 'right-[423px]' : ''}
        ${tableType === 'customers' ? 'right-[436px]' : ''}
        ${tableType === 'fabrics' ? 'top-[-75px] right-[150px]' : ''}
        ${tableType === 'components' ? 'top-[-75px] right-[190px]' : ''}
        ${tableType === 'items' ? 'top-[-75px] right-[566px]' : ''}
        `
        }>
            <span className={`mr-2 uppercase text-xs text-gray-700 font-bold`}>Search:{' '}</span>
            <input className='input inline w-auto min-w-[250px]'
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </div>
    )
}

export default function Table({ columns, data, setEditSelect, setDeleteSelect, currentPage, setCurrentPage, tableType, hiddenColumns, onDeleteHandler, openEditView, setData, onReorder, setSortedIds, onDuplicateHandler, projectType, onChangeExportHandler, onChangeExportHandlerAll }) {

    const [isOpen, setIsOpen] = useState(false);
    const [itemId, setItemId] = useState('');
    const [activeId, setActiveId] = useState();
    const items = useMemo(() => data?.map(({ id }) => id), [data]);
    const [itemsForExport, setItemsForExport] = useState([]);


    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setPagination,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
        state,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: currentPage,
            hiddenColumns: hiddenColumns ? hiddenColumns : [],
            selectedRowIds: itemsForExport
        },

    },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <>
                            {tableType == "products" &&
                                <div className='pl-[3.5rem] flex'>
                                    <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} onClick={()=>{
                                        onChangeExportHandlerAll(!getToggleAllPageRowsSelectedProps().checked);
                                    }} />
                                    <span className="ml-2">Export&nbsp;To&nbsp;Report</span>
                                </div>
                            }
                        </>
                    ),
                    Cell: ({ row }) => (
                        <>
                            {tableType == "products" &&
                                <div className='pl-6'>
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={()=>{
                                        onChangeExportHandler(row.values.id, JSON.stringify(!row.isSelected));
                                        
                                    }} />
                                </div>
                            }
                        </>
                    ),
                },
                ...columns,
                {
                    id: 'edit',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            Actions
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div className='flex'>
                            <button data-tooltip-delay-show={300} className='btn p-2 mr-2 flex items-center tooltip-edit' onClick={(e) => {
                                e.preventDefault();
                                openEditView(row.values.id);
                            }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>

                            </button>
                            <Tooltip anchorSelect=".tooltip-edit" place="top" className="tooltip">
                                Edit
                            </Tooltip>
                            {tableType == "projects" &&
                                <>
                                    <Link data-tooltip-delay-show={300} to={`/products/${row.values.id}`} state={{ projectType: projectType }} className={`btn p-2 mr-2 flex items-center tooltip-viewproject`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                    </svg>
                                    </Link>
                                    <Tooltip anchorSelect=".tooltip-viewproject" place="top" className="tooltip">
                                        View Project Items
                                    </Tooltip>
                                </>
                            }
                            <button data-tooltip-delay-show={300} className='btn p-2 mr-2 flex items-center tooltip-duplicate' onClick={(e) => {
                                e.preventDefault();
                                onDuplicateHandler(row.values.id);
                            }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                </svg>
                            </button>
                            <Tooltip anchorSelect=".tooltip-duplicate" place="top" className="tooltip">
                                Duplicate
                            </Tooltip>
                            <button data-tooltip-delay-show={300} className='btn p-2 bg-red-200 hover:bg-red-300 flex items-center tooltip-delete' onClick={(e) => {
                                e.preventDefault();
                                setItemId(row.values.id);
                                setIsOpen(true);
                            }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </button>
                            <Tooltip anchorSelect=".tooltip-delete" place="top" className="tooltip">
                                Delete
                            </Tooltip>
                        </div>
                    ),
                },
            ])
        }

    );

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            setData((data) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(data, oldIndex, newIndex);
            });
            //onReorder();
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    const selectedRow = useMemo(() => {
        if (!activeId) {
            return null;
        }
        const row = rows.find(({ original }) => original.id === activeId);
        prepareRow(row);
        return row;
    }, [activeId, rows, prepareRow]);

    useEffect(() => {
        //console.log("Table Data",data);
        let exportIndexes = [];
        data?.map((item) => {
            if (item.export !== "false") {
                exportIndexes[indexOf(data, item)] = true;
            }
        });
        setItemsForExport(exportIndexes);
    }, [data, selectedRow])


    useEffect(() => {
        const sortedIds = [];
        rows.map((row, idx) => {
            sortedIds.push({ "id": row.original.id, "order": idx });
        });
        setSortedIds(sortedIds);
    }, [rows]);
    

    // Render the UI for your table
    return (
        <>
            <div className='relative'>
                <div className='overflow-scroll w-full'>
                    <DndContext
                        sensors={sensors}
                        onDragEnd={handleDragEnd}
                        onDragStart={handleDragStart}
                        onDragCancel={handleDragCancel}
                        collisionDetection={closestCenter}
                        modifiers={[restrictToVerticalAxis]}
                    >
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            tableType={tableType}
                        />
                        <table {...getTableProps()} className="w-full text-sm text-left text-gray-500">
                            <thead className='text-xs text-gray-700 uppercase bg-slate-100'>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className='py-6 px-4'>
                                                <div className='flex items-center'>
                                                    <span>{column.render('Header')}</span>
                                                    <span className='ml-2'>
                                                        {column.isSorted ? (column.isSortedDesc ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                        </svg>
                                                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg>
                                                        ) : ''}
                                                    </span>
                                                </div>

                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="bg-white">
                                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return <DraggableTableRow key={row.original.id} row={row} tableType={tableType} />;
                                    })}
                                </SortableContext>
                            </tbody>
                        </table>
                        <DragOverlay>
                            {activeId && (
                                <table className="w-full text-sm text-left text-gray-500">
                                    <tbody>
                                        <StaticTableRow row={selectedRow} />
                                    </tbody>
                                </table>
                            )}
                        </DragOverlay>
                    </DndContext>
                </div>
                <div className="pagination flex justify-end py-2 px-6 items-center text-sm text-gray-700">
                    <button className='p-2' onClick={() => {
                        gotoPage(0);
                        setCurrentPage(0);
                    }
                    } disabled={!canPreviousPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <button className='p-2' onClick={() => {
                        previousPage();
                        setCurrentPage(pageIndex - 1);
                    }} disabled={!canPreviousPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <button className='p-2' onClick={() => {
                        nextPage();
                        setCurrentPage(pageIndex + 1);
                    }} disabled={!canNextPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <button className='p-2' onClick={() => {
                        gotoPage(pageCount - 1);
                        setCurrentPage(pageCount - 1);
                    }} disabled={!canNextPage}>
                        {<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>
                        }
                    </button>{' '}
                    <span className='p-2'>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span className='p-2'>
                        Go to page:{' '}
                        <input
                            className='py-1 px-2 w-20 border rounded'
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    {/* <pre>
                        <code>
                            {JSON.stringify(
                                {
                                    selectedRowIds: selectedRowIds,
                                    'selectedFlatRows[].original': selectedFlatRows.map(
                                        d => d.original
                                    ),
                                },
                                null,
                                2
                            )}
                        </code>
                    </pre> */}
                </div>
            </div>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        //setDeleteSelect(itemId)
                        onDeleteHandler(itemId);
                        setIsOpen(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
        </>
    );
}
