export const productComponentsFormElements = (suppliers, currencies, currencyType, discounts, markups, onSupplierFormClick, useDefaultMarkup, defaultMarkup, qtyActive ) => [
    { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
    { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
    { elementLabel: "Supplier", elementName: "supplier", elementType: "select", className: "w-[49%]", elementOptions: suppliers },
    { elementLabel: "Add Supplier", elementName: "add_supplier", elementType: "button", className: "w-[49%] mt-5", elementAction: onSupplierFormClick },
    { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },

    { elementLabel: "Multiple of this Item", elementName: "qtyActive", elementType: "checkbox", className: "w-full" },
    { elementLabel: "Quantity", elementName: "quantity", elementType: "number", className: "w-[49%]", disabled: qtyActive >= 1 ? false : true },

    { elementLabel: "Unit Price", elementName: "unit_price", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Currency", elementName: "currency_type", elementType: "select", className: "w-[49%]", elementOptions: currencies },
    { elementLabel: "Exchange Rate", elementName: "exchange_rate", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "Unit Price (Converted)", elementName: "unit_price_no_percent", elementType: "number", className: `w-full ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "EU Additional %", elementName: "add_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}` },
    { elementLabel: "EU Additional % Price/Unit", elementName: "eu_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: `Discounts: ${discounts.map((discount)=>(" "+discount.amount+'%'))}`, elementName: "discounts", elementType: "note", className: `w-full ${discounts.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Duties Provision: ${markups.map((markup)=>(" "+markup.amount * -1 +'%'))}`, elementName: "markups", elementType: "note", className: `w-full ${markups.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Use default Markup (${defaultMarkup}%)`, elementName: "useDefaultMarkup", elementType: "checkbox", className: "w-full mb-4" },
    { elementLabel: "Markup (%)", elementName: "markup", elementType: "number", className: `w-[49%] ${useDefaultMarkup == 1 ? "hidden" : ""}` },
    
    { elementLabel: "Total", elementName: "total", elementType: "number", className: "w-full", disabled: true },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
];

export const fabricFormElements = (suppliers, currencies, currencyType, discounts, markups, onSupplierFormClick, unitsOfMeasurement, unitOfMeasurement, useDefaultMarkup, defaultMarkup) => [
    { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
    { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
    { elementLabel: "Supplier", elementName: "supplier", elementType: "select", className: "w-[49%]", elementOptions: suppliers },
    { elementLabel: "Add Supplier", elementName: "add_supplier", elementType: "button", className: "w-[49%] mt-5", elementAction: onSupplierFormClick },
    { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Currency", elementName: "currency_type", elementType: "select", className: "w-[49%]", elementOptions: currencies },
    { elementLabel: "Unit of Measurement", elementName: "unit_of_measurement", elementType: "select", className: "w-[49%]", elementOptions: unitsOfMeasurement },
    { elementLabel: `${unitOfMeasurement == "m2" ? "Meters Squared" : "Meter"}`, elementName: "quantity", elementType: "number", className: "w-[49%]" },
    { elementLabel: `${unitOfMeasurement == "m2" ? "Price/Meter Squared" : "Price/Meter"}`, elementName: "unit_price", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Exchange Rate", elementName: "exchange_rate", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "Unit Price (Converted)", elementName: "unit_price_no_percent", elementType: "number", className: `w-full ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "EU Additional %", elementName: "add_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}` },
    { elementLabel: "EU Additional % Price/Unit", elementName: "eu_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: `Discounts: ${discounts.map((discount)=>(" "+discount.amount+'%'))}`, elementName: "discounts", elementType: "note", className: `w-full ${discounts.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Duties Provision: ${markups.map((markup)=>(" "+markup.amount * -1 +'%'))}`, elementName: "markups", elementType: "note", className: `w-full ${markups.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Use default Markup (${defaultMarkup}%)`, elementName: "useDefaultMarkup", elementType: "checkbox", className: "w-full mb-4" },
    { elementLabel: "Markup (%)", elementName: "markup", elementType: "number", className: `w-[49%] ${useDefaultMarkup == 1 ? "hidden" : ""}` },

    { elementLabel: "Total", elementName: "total", elementType: "number", className: "w-full", disabled: true },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
];

export const finishesFormElements = (suppliers, currencies, currencyType, discounts, markups, onSupplierFormClick, useDefaultMarkup, defaultMarkup) => [
    { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
    { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
    { elementLabel: "Supplier", elementName: "supplier", elementType: "select", className: "w-[49%]", elementOptions: suppliers },
    { elementLabel: "Add Supplier", elementName: "add_supplier", elementType: "button", className: "w-[49%] mt-5", elementAction: onSupplierFormClick },
    { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Quantity", elementName: "quantity", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Unit Price", elementName: "unit_price", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Currency", elementName: "currency_type", elementType: "select", className: "w-[49%]", elementOptions: currencies },
    { elementLabel: "Exchange Rate", elementName: "exchange_rate", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "Unit Price (Converted)", elementName: "unit_price_no_percent", elementType: "number", className: `w-full ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "EU Additional %", elementName: "add_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}` },
    { elementLabel: "EU Additional % Price/Unit", elementName: "eu_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: `Discounts: ${discounts.map((discount)=>(" "+discount.amount+'%'))}`, elementName: "discounts", elementType: "note", className: `w-full ${discounts.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Duties Provision: ${markups.map((markup)=>(" "+markup.amount * -1 +'%'))}`, elementName: "markups", elementType: "note", className: `w-full ${markups.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Use default Markup (${defaultMarkup}%)`, elementName: "useDefaultMarkup", elementType: "checkbox", className: "w-full mb-4" },
    { elementLabel: "Markup (%)", elementName: "markup", elementType: "number", className: `w-[49%] ${useDefaultMarkup == 1 ? "hidden" : ""}` },

    { elementLabel: "Total", elementName: "total", elementType: "number", className: "w-full", disabled: true },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
]

export const transportFormElements = (suppliers, currencies, currencyType, discounts, markups, onSupplierFormClick, useDefaultMarkup, defaultMarkup) => [
    { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
    { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
    { elementLabel: "Supplier", elementName: "supplier", elementType: "select", className: "w-[49%]", elementOptions: suppliers },
    { elementLabel: "Add Supplier", elementName: "add_supplier", elementType: "button", className: "w-[49%] mt-5", elementAction: onSupplierFormClick },
    { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Cubic Meters", elementName: "quantity", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Shipping Cost", elementName: "unit_price", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Currency", elementName: "currency_type", elementType: "select", className: "w-[49%]", elementOptions: currencies },
    { elementLabel: "Exchange Rate", elementName: "exchange_rate", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "Price (Converted)", elementName: "unit_price_no_percent", elementType: "number", className: `w-full ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "EU Additional %", elementName: "add_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}` },
    { elementLabel: "EU Additional % Price/Unit", elementName: "eu_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: `Discounts: ${discounts.map((discount)=>(" "+discount.amount+'%'))}`, elementName: "discounts", elementType: "note", className: `w-full ${discounts.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Duties Provision: ${markups.map((markup)=>(" "+markup.amount * -1 +'%'))}`, elementName: "markups", elementType: "note", className: `w-full ${markups.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Use default Markup (${defaultMarkup}%)`, elementName: "useDefaultMarkup", elementType: "checkbox", className: "w-full mb-4" },
    { elementLabel: "Markup (%)", elementName: "markup", elementType: "number", className: `w-[49%] ${useDefaultMarkup == 1 ? "hidden" : ""}` },

    { elementLabel: "Total", elementName: "total", elementType: "number", className: "w-full", disabled: true },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
]

export const courierFormElements = (suppliers, currencies, currencyType, discounts, markups, onSupplierFormClick, useDefaultMarkup, defaultMarkup) => [
    { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
    { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
    { elementLabel: "Supplier", elementName: "supplier", elementType: "select", className: "w-[49%]", elementOptions: suppliers },
    { elementLabel: "Add Supplier", elementName: "add_supplier", elementType: "button", className: "w-[49%] mt-5", elementAction: onSupplierFormClick },
    { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Cubic Meters", elementName: "quantity", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Shipping Cost", elementName: "unit_price", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Currency", elementName: "currency_type", elementType: "select", className: "w-[49%]", elementOptions: currencies },
    { elementLabel: "Exchange Rate", elementName: "exchange_rate", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "Price (Converted)", elementName: "unit_price_no_percent", elementType: "number", className: `w-full ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "EU Additional %", elementName: "add_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}` },
    { elementLabel: "EU Additional % Price/Unit", elementName: "eu_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: `Discounts: ${discounts.map((discount)=>(" "+discount.amount+'%'))}`, elementName: "discounts", elementType: "note", className: `w-full ${discounts.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Duties Provision: ${markups.map((markup)=>(" "+markup.amount * -1 +'%'))}`, elementName: "markups", elementType: "note", className: `w-full ${markups.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Use default Markup (${defaultMarkup}%)`, elementName: "useDefaultMarkup", elementType: "checkbox", className: "w-full mb-4" },
    { elementLabel: "Markup (%)", elementName: "markup", elementType: "number", className: `w-[49%] ${useDefaultMarkup == 1 ? "hidden" : ""}` },

    { elementLabel: "Total", elementName: "total", elementType: "number", className: "w-full", disabled: true },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
]

export const customsFormElements = (suppliers, currencies, currencyType, discounts, markups, onSupplierFormClick, useDefaultMarkup, defaultMarkup) => [
    { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
    { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
    { elementLabel: "Supplier", elementName: "supplier", elementType: "select", className: "w-[49%]", elementOptions: suppliers },
    { elementLabel: "Add Supplier", elementName: "add_supplier", elementType: "button", className: "w-[49%] mt-5", elementAction: onSupplierFormClick },
    { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Quantity", elementName: "quantity", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Unit Price", elementName: "unit_price", elementType: "number", className: "w-[49%]" },
    { elementLabel: "Currency", elementName: "currency_type", elementType: "select", className: "w-[49%]", elementOptions: currencies },
    { elementLabel: "Exchange Rate", elementName: "exchange_rate", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "Unit Price (Converted)", elementName: "unit_price_no_percent", elementType: "text", className: `w-full ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: "EU Additional %", elementName: "add_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}` },
    { elementLabel: "EU Additional % Price/Unit", elementName: "eu_percent", elementType: "number", className: `w-[49%] ${currencyType !== "EUR" ? "hidden" : ""}`, disabled: true },
    { elementLabel: `Discounts: ${discounts.map((discount)=>(" "+discount.amount+'%'))}`, elementName: "discounts", elementType: "note", className: `w-full ${discounts.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Duties Provision: ${markups.map((markup)=>(" "+markup.amount * -1 +'%'))}`, elementName: "markups", elementType: "note", className: `w-full ${markups.length ? "" : "hidden"}`, disabled: true },
    { elementLabel: `Use default Markup (${defaultMarkup}%)`, elementName: "useDefaultMarkup", elementType: "checkbox", className: "w-full mb-4" },
    { elementLabel: "Markup (%)", elementName: "markup", elementType: "number", className: `w-[49%] ${useDefaultMarkup == 1 ? "hidden" : ""}` },
    
    { elementLabel: "Total", elementName: "total", elementType: "number", className: "w-full", disabled: true },
    { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
    { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
]