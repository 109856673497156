import React from "react";
import { DragHandle } from "./DragHandle";

export const StaticTableRow = ({ row }) => {
    return (
        <tr {...row.getRowProps()} className="table_row">
            {row.cells.map((cell, i) => {
                if (i === 0) {
                    return (
                        <td {...cell.getCellProps()} className='p-4'>
                            <div className='w-full overflow-auto relative flex items-center'>
                                <DragHandle isDragging />
                                {cell.render("Cell")}
                            </div>
                        </td>
                    );
                }
                return (
                    <td {...cell.getCellProps()} className='p-4'>
                        <div className='w-full max-h-24 block overflow-auto'>
                        {cell.render("Cell")}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
};
