import React, { useState, useContext, useEffect } from 'react'
import * as api from '../../api/ProductsApi';
import { addItem } from '../../api/ItemsApi';
import { ProductsContext } from '../../pages/Products'
import FormElement from '../FormElement';
import Modal from '../Modal';
import Loader from '../Loader';
import Dropzone from '../Dropzone';
import { Tab } from '@headlessui/react'
import Fabrics from '../Fabrics/Fabrics';
import ProductComponents from '../ProductComponents/ProductComponents';
import { UKPriceFormat, addVat, getVat, dateNow } from '../../config';
import TransportForm from '../Transport/TransportForm';
import Items from '../Items/Items';
import * as icons from "../../images/imagesSvg";
import * as configuration from "../../config";
import Toast from '../Toast';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const options = [
    { value: "", icon: icons.warnSvg, color: "red" },
    { value: "na", icon: icons.minusSvg, color: "gray" },
    { value: "complete", icon: icons.checkSvg, color: "green" },
    { value: "inprogress", icon: icons.timeSvg, color: "yellow" },
]

const StatusIcon = (props) => {
    return (
        <div className={`ml-auto rounded-full p-1 ${props.itemsStatus ? options[options.findIndex(object => {
            return object.value === props.itemsStatus;
        })].color : options[0].color} text-blue-900`}>
            {props.itemsStatus ? options[options.findIndex(object => {
                return object.value === props.itemsStatus;
            })].icon : options[0].icon}
        </div>
    )
}

function EditProductForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const [productId, setProductId] = useState(props.productId);

    const { refresh, setRefresh, isOpen, setIsOpen, productCodes, areas } = useContext(ProductsContext);

    const [isOpen2, setIsOpen2] = useState(false);
    const [productSuppliers, setProductSuppliers] = useState({});
    const [productfiles, setProductfiles] = useState([]);
    const [newProductfiles, setNewProductfiles] = useState([]);

    const [fabricsTotal, setFabricsTotal] = useState(0);
    const [componentsTotal, setComponentsTotal] = useState(0);
    const [finishesTotal, setFinishesTotal] = useState(0);
    const [transportTotal, setTransportTotal] = useState(0);
    const [courierTotal, setCourierTotal] = useState(0);
    const [customsTotal, setCustomsTotal] = useState(0);

    const [fabricsStatus, setFabricsStatus] = useState("");
    const [componentsStatus, setComponentsStatus] = useState("");
    const [finishesStatus, setFinishesStatus] = useState("");
    const [transportStatus, setTransportStatus] = useState("");
    const [courierStatus, setCourierStatus] = useState("");
    const [customsStatus, setCustomsStatus] = useState("");

    const [extraQty, setExtraQty] = useState(0);
    const [extraUnitPrice, setExtraUnitPrice] = useState(0);
    const [extraTotal, setExtraTotal] = useState(0);

    const [markup, setMarkup] = useState(0);
    const [transportMarkup, setTransportMarkup] = useState(0);

    const [itemDiscounts, setItemDiscounts] = useState([]);
    const [productTotal, setProductTotal] = useState(0);

    const [editorValues, setEditorValues] = useState([]);

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const [productCode, setProductCode] = useState("");

    const [productData, setProductData] = useState({
        "name": "",
        "export": "",
        "description": "",
        "supplier": "",
        "files": "",
        "quantity": "",
        "width": "",
        "length": "",
        "height": "",
        "weight_net": "",
        "weight_gross": "",
        "unit_price": "",
        "code": "",
        "project_id": props.project_id,
        "created_by": userid,
        "modified_by": userid,
        "product_type": props.productType
    });


    const doToast = (type, title, message, doRefresh) => {
        if (doRefresh) {
            setRefresh(!refresh);
        }
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }

    useEffect(() => {
        api.getProductsSuppliers(apikey).then((resp) => {
            let productCategoryData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    productCategoryData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'code': item.attributes.code,
                        }
                    )
                }
            });
            setProductSuppliers(productCategoryData);
        });
    }, []);


    useEffect(() => {
        api.getProduct(apikey, productId).then((resp) => {
            setProductData({
                "created_by": resp.attributes.created_by,
                "modified_by": userid,
                "name": resp.attributes.name,
                "description": resp.attributes.description,
                "supplier": resp.attributes.supplier,
                "files": resp.attributes.files,
                "quantity": resp.attributes.quantity,
                "width": resp.attributes.width,
                "length": resp.attributes.length,
                "height": resp.attributes.height,
                "weight_net": resp.attributes.weight_net,
                "weight_gross": resp.attributes.weight_gross,
                "unit_price": resp.attributes.unit_price,
                "fabrics_total": resp.attributes.fabrics_total,
                "components_total": resp.attributes.components_total,
                "finishes_total": resp.attributes.finishes_total,
                "transport_total": resp.attributes.transport_total,
                "courier_total": resp.attributes.courier_total,
                "customs_total": resp.attributes.customs_total,
                "fabrics_status": resp.attributes.fabrics_status,
                "components_status": resp.attributes.components_status,
                "finishes_status": resp.attributes.finishes_status,
                "transport_status": resp.attributes.transport_status,
                "courier_status": resp.attributes.courier_status,
                "customs_status": resp.attributes.customs_status,
                "code": resp.attributes.code,
                "project_id": resp.attributes.project_id,
                "modified_by": userid,
                "markup": resp.attributes.markup,
                "transport_markup": resp.attributes.transport_markup,
                "product_type": resp.attributes.product_type,
                "extra_total": resp.attributes.extra_total,
                "area": resp.attributes.area,
                "unit_price": resp.attributes.extra_total / resp.attributes.quantity,
                "total":
                    (resp.attributes.quantity * resp.attributes.fabrics_total) +
                    (resp.attributes.quantity * resp.attributes.components_total) +
                    (resp.attributes.quantity * resp.attributes.finishes_total) +
                    (resp.attributes.quantity * resp.attributes.transport_total) +
                    (resp.attributes.quantity * resp.attributes.courier_total) +
                    (resp.attributes.quantity * resp.attributes.customs_total)
            });
            setProductfiles(resp.attributes.files.split(","));
            setFabricsTotal(resp.attributes.fabrics_total);
            setComponentsTotal(resp.attributes.components_total);
            setFinishesTotal(resp.attributes.finishes_total);
            setTransportTotal(resp.attributes.transport_total);
            setCourierTotal(resp.attributes.courier_total);
            setCustomsTotal(resp.attributes.customs_total);

            setFabricsStatus(resp.attributes.fabrics_status);
            setComponentsStatus(resp.attributes.components_status);
            setFinishesStatus(resp.attributes.finishes_status);
            setTransportStatus(resp.attributes.transport_status);
            setCourierStatus(resp.attributes.courier_status);
            setCustomsStatus(resp.attributes.customs_status);

            setExtraUnitPrice((resp.attributes.extra_total) / resp.attributes.quantity);
            setExtraTotal(resp.attributes.extra_total);

            setMarkup(+resp.attributes.markup);
            setTransportMarkup(+resp.attributes.transport_markup);

            setProductCode(resp.attributes.code);
        });
    }, []);

    useEffect(() => { setProductData((prevState) => { return { ...prevState, "fabrics_total": fabricsTotal } }) }, [fabricsTotal]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "components_total": componentsTotal } }) }, [componentsTotal]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "finishes_total": finishesTotal } }) }, [finishesTotal]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "transport_total": transportTotal } }) }, [transportTotal]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "courier_total": courierTotal } }) }, [courierTotal]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "customs_total": customsTotal } }) }, [customsTotal]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "discounts": itemDiscounts } }) }, [itemDiscounts]);

    useEffect(() => { setProductData((prevState) => { return { ...prevState, "fabrics_status": fabricsStatus } }) }, [fabricsStatus]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "components_status": componentsStatus } }) }, [componentsStatus]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "finishes_status": finishesStatus } }) }, [finishesStatus]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "transport_status": transportStatus } }) }, [transportStatus]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "courier_status": courierStatus } }) }, [courierStatus]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "customs_status": customsStatus } }) }, [customsStatus]);
    useEffect(() => { setProductData((prevState) => { return { ...prevState, "discounts": itemDiscounts } }) }, [itemDiscounts]);

    useEffect(() => { setProductData((prevState) => { return { ...prevState, "extra_total": extraUnitPrice * extraQty } }) }, [extraUnitPrice, extraQty]);

    useEffect(() => {
        console.log("productTotalChanged",
            +productData.quantity,
            +fabricsTotal,
            +componentsTotal,
            +finishesTotal,
            +transportTotal,
            +courierTotal,
            +customsTotal,
            +productData.markup,
            +productData.transport_markup)

        setProductTotal(
            configuration.calcTotal(
                +productData.quantity,
                +fabricsTotal,
                +componentsTotal,
                +finishesTotal,
                +transportTotal,
                +courierTotal,
                +customsTotal,
                +productData.markup,
                +productData.transport_markup,
                0
            ).fullTotalWithCommissionAndTransport
        );
    }, [productData.quantity, fabricsTotal, componentsTotal, finishesTotal, transportTotal, courierTotal, customsTotal, productData.markup, productData.transport_markup])


    const onSubmitHandler = (e, submitType) => {
        e.preventDefault();
        if (props.selectedModal === "edit") {
            let otherProductCodes = productCodes.filter(item => item !== productCode);
            if (otherProductCodes.includes(productData.code)) {
                doToast("error", "Error", "Product code already exists");
                return;
            }
            api.editProduct(apikey, { ...productData, ...editorValues }, productId).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        } else {
            if (productCodes.includes(productData.code)) {
                doToast("error", "Error", "Product code already exists");
                return;
            }
            api.addProduct(apikey, { ...productData, ...editorValues }).then((resp) => {
                setProductId(resp.id);
                props.setSelectedModal("edit");
                doToast("success", "Success", "Entry saved", true);

                //-add Item
                const productComponentData = {
                    "name": productData.name + " (Component)",
                    "description": productData.description,
                    "created_by": userid,
                    "modified_by": userid,
                    "quantity": "1",
                    "product_id": resp.id,
                    "code": productData.code,
                    "itemtype": "MAT",
                    "files": [],
                    "discounts": [],
                }

                const productFabricData = {
                    "name": productData.name + " (Fabric)",
                    "description": productData.description,
                    "created_by": userid,
                    "modified_by": userid,
                    "quantity": "1",
                    "product_id": resp.id,
                    "code": productData.code,
                    "itemtype": "FAB",
                    "files": [],
                    "discounts": [],
                }

                const productFinishData = {
                    "name": productData.name + " (Finish)",
                    "description": productData.description,
                    "created_by": userid,
                    "modified_by": userid,
                    "quantity": "1",
                    "product_id": resp.id,
                    "code": productData.code,
                    "itemtype": "FIN",
                    "files": [],
                    "discounts": [],
                }

                const productTransportData = {
                    "name": productData.name + " (Transport)",
                    "description": productData.description,
                    "created_by": userid,
                    "modified_by": userid,
                    "quantity": "1",
                    "product_id": resp.id,
                    "code": productData.code,
                    "itemtype": "TRA",
                    "files": [],
                    "discounts": [],
                }

                const productCourierData = {
                    "name": productData.name + " (Courier)",
                    "description": productData.description,
                    "created_by": userid,
                    "modified_by": userid,
                    "quantity": "1",
                    "product_id": resp.id,
                    "code": productData.code,
                    "itemtype": "COU",
                    "files": [],
                    "discounts": [],
                }

                const productCustomsData = {
                    "name": productData.name + " (Customs)",
                    "description": productData.description,
                    "created_by": userid,
                    "modified_by": userid,
                    "quantity": "1",
                    "product_id": resp.id,
                    "code": productData.code,
                    "itemtype": "CUS",
                    "files": [],
                    "discounts": [],
                }

                addItem(apikey, {...productComponentData, ...editorValues}).then((resp)=>{
                    doToast("success", "Success", "Product Component saved", true);
                }).catch((resp) => {
                    doToast("error", "Error", resp.message);
                });

                addItem(apikey, {...productFabricData, ...editorValues}).then((resp)=>{
                    doToast("success", "Success", "Fabric saved", true);
                }).catch((resp) => {
                    doToast("error", "Error", resp.message);
                });

                addItem(apikey, {...productFinishData, ...editorValues}).then((resp)=>{
                    doToast("success", "Success", "Finish saved", true);
                }).catch((resp) => {
                    doToast("error", "Error", resp.message);
                });

                addItem(apikey, {...productTransportData, ...editorValues}).then((resp)=>{
                    doToast("success", "Success", "Transport saved", true);
                }).catch((resp) => {
                    doToast("error", "Error", resp.message);
                });

                addItem(apikey, {...productCourierData, ...editorValues}).then((resp)=>{
                    doToast("success", "Success", "Courier saved", true);
                }).catch((resp) => {
                    doToast("error", "Error", resp.message);
                });

                addItem(apikey, {...productCustomsData, ...editorValues}).then((resp)=>{
                    doToast("success", "Success", "Customs saved", true);
                }).catch((resp) => {
                    doToast("error", "Error", resp.message);
                });
                //-

                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        }
        console.log("sendProductData", productData);
    }

    const [imagesArr, setImagesArr] = useState([]);

    useEffect(() => {
        setNewProductfiles(imagesArr);
    }, [imagesArr])

    useEffect(() => {
        let newProds = productfiles.concat(newProductfiles);

        var filtered = newProds.filter(function (el) {
            return el != "";
        });

        setProductData((prevState) => {
            return {
                ...prevState,
                files: filtered.join()
            }
        });
    }, [newProductfiles])

    const fileUploadHandler = (data) => {
        data.map((item) => {
            setImagesArr((imagesArr) => {
                return [...imagesArr, item.name.replace(/[^A-Za-z0-9.-]+/g, "")
                ]
            });
        })
        api.addProductImage(apikey, data).then((resp) => {
            doToast("success", "Success", "Image uploaded");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const fileDeleteHandler = (imagesArr, filename) => {
        setProductfiles(imagesArr);
        setNewProductfiles([]);
        api.deleteProductImage(apikey, filename).then((resp) => {
            setProductData((prevState) => {
                return {
                    ...prevState,
                    files: imagesArr.join()
                }
            });
            doToast("success", "Success", "Image deleted");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const fileRemoveHandler = (imagesArr) => {
        setProductData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
    }

    const fileReorderHandler = (imagesArr) => {
        console.log(imagesArr)
        setProductData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
    }

    const FormElements = [
        { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[32%]", required: "true" },
        { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[32%]" },
        { elementLabel: "Area", elementName: "area", elementType: "select", className: "w-[32%]", elementOptions: areas },
        { elementLabel: "Quantity", elementName: "quantity", elementType: "text", className: "w-[32%]" },
        { elementLabel: "Default Markup | Components | (%)", elementName: "markup", elementType: "text", className: "w-[32%]" },
        { elementLabel: "Default Markup | all Other Items | (%)", elementName: "transport_markup", elementType: "text", className: "w-[32%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
    ]

    const FormElementsExtra = [
        { elementLabel: "Name", elementName: "name", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Code", elementName: "code", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Quantity", elementName: "quantity", elementType: "number", className: "w-[32%]" },
        { elementLabel: "Unit Price", elementName: "unit_price", elementType: "number", className: "w-[32%]" },
        { elementLabel: "Total", elementName: "extra_total", elementType: "text", className: "w-[32%]", disabled: true },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Files", elementName: "files", elementType: "files", className: "w-full" },
    ]

    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
                <button className="btn flex items-center" onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>
                    <span className='ml-2'>Save</span>
                </button>
                <button className={`btn flex items-center ml-2 ${props.selectedModal === "add" ? "mr-auto" : ""}`} onClick={(e) => {
                    onSubmitHandler(e, "save_and_close");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                {props.selectedModal !== "add" && <button className="btn flex items-center ml-2 mr-auto" onClick={(e) => {
                    e.preventDefault();
                    props.onDuplicateHandler(productId);
                    setIsOpen(false);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>


                    <span className='ml-2'>Duplicate</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                    e.preventDefault();
                    setIsOpen2(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                    <span className='ml-2'>Delete</span>
                </button>}
            </div>
            <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            {props.productType === "product" &&
                <Tab.Group>
                    <Tab.List className="flex space-x-1 bg-slate-100 p-1 mb-2">

                        <Tab className={({ selected }) =>
                            classNames(
                                'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                                selected
                                    ? 'bg-white text-gray-700'
                                    : 'hover:bg-white/[0.8]'
                            )
                        }><span className='text-sm'>Product Details</span><br /><span className='text-xs'>Total: {UKPriceFormat(productTotal ? productTotal : "0.00")}</span>
                        </Tab>


                        {productId !== "" &&
                            <Tab className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 flex justify-between items-center rounded-lg text-sm font-medium leading-5 text-gray-500 text-left py-4 px-6',
                                    selected
                                        ? 'bg-white text-gray-700'
                                        : 'hover:bg-white/[0.8]')

                            }><div><span className='text-sm'>Components</span><br /><span className='text-xs'>Total: {UKPriceFormat(componentsTotal)}</span>
                                </div>
                                <StatusIcon itemsStatus={componentsStatus} />
                            </Tab>}
                        {productId === "" &&
                            <Tab disabled className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 first-letter: rounded-lg py-4 px-6 text-sm font-medium leading-5 text-gray-500 text-left')
                            }>Components<br /><span className='text-xs'>(Save Details to enable)</span>
                            </Tab>}


                        {productId !== "" &&
                            <Tab className={({ selected }) =>
                                classNames(
                                    'w-full shadow bg-slate-50 flex justify-between items-center rounded-lg text-sm font-medium leading-5 text-gray-500 text-left py-4 px-6',
                                    selected
                                        ? 'bg-white text-gray-700'
                                        : 'hover:bg-white/[0.8]'
                                )
                            }><div><span>Fabrics</span><br /><span className='text-xs'>Total: {UKPriceFormat(fabricsTotal)}</span>
                                </div>
                                <StatusIcon itemsStatus={fabricsStatus} />
                            </Tab>}
                        {productId === "" &&
                            <Tab disabled className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500 px-6 text-left')
                            }>Fabrics<br /><span className='text-xs'>(Save Details to enable)</span>
                            </Tab>}


                        {productId !== "" &&
                            <Tab className={({ selected }) =>
                                classNames(
                                    'w-full shadow bg-slate-50 flex justify-between items-center rounded-lg text-sm font-medium leading-5 text-gray-500 text-left py-4 px-6',

                                    selected
                                        ? 'bg-white text-gray-700'
                                        : 'hover:bg-white/[0.8]'
                                )
                            }><div><span>Finishes</span><br /><span className='text-xs'>Total: {UKPriceFormat(finishesTotal)}</span></div>
                                <StatusIcon itemsStatus={finishesStatus} />
                            </Tab>}
                        {productId === "" &&
                            <Tab disabled className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500 px-6 text-left')
                            }>Finishes<br /><span className='text-xs'>(Save Details to enable)</span>
                            </Tab>}


                        {productId !== "" &&
                            <Tab className={({ selected }) =>
                                classNames(
                                    'w-full shadow bg-slate-50 flex justify-between items-center rounded-lg text-sm font-medium leading-5 text-gray-500 text-left py-4 px-6',

                                    selected
                                        ? 'bg-white text-gray-700'
                                        : 'hover:bg-white/[0.8]'
                                )
                            }><div><span>Transport</span><br /><span className='text-xs'>Total: {UKPriceFormat(transportTotal)}</span></div>
                                <StatusIcon itemsStatus={transportStatus} />
                            </Tab>}
                        {productId === "" &&
                            <Tab disabled className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500 px-6 text-left')
                            }>Transport<br /><span className='text-xs'>(Save Details to enable)</span>
                            </Tab>}


                        {productId !== "" &&
                            <Tab className={({ selected }) =>
                                classNames(
                                    'w-full shadow bg-slate-50 flex justify-between items-center rounded-lg text-sm font-medium leading-5 text-gray-500 text-left py-4 px-6',

                                    selected
                                        ? 'bg-white text-gray-700'
                                        : 'hover:bg-white/[0.8]'
                                )
                            }><div><span>Courier</span><br /><span className='text-xs'>Total: {UKPriceFormat(courierTotal)}</span></div>
                                <StatusIcon itemsStatus={courierStatus} />
                            </Tab>}
                        {productId === "" &&
                            <Tab disabled className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500 px-6 text-left')
                            }>Courier<br /><span className='text-xs'>(Save Details to enable)</span>
                            </Tab>}


                        {productId !== "" &&
                            <Tab className={({ selected }) =>
                                classNames(
                                    'w-full shadow bg-slate-50 flex justify-between items-center rounded-lg text-sm font-medium leading-5 text-gray-500 text-left py-4 px-6',

                                    selected
                                        ? 'bg-white text-gray-700'
                                        : 'hover:bg-white/[0.8]'
                                )
                            }><div><span>Customs</span><br /><span className='text-xs'>Total: {UKPriceFormat(customsTotal)}</span></div>
                                <StatusIcon itemsStatus={customsStatus} />
                            </Tab>}
                        {productId === "" &&
                            <Tab disabled className={({ selected }) =>
                                classNames('w-full shadow bg-slate-50 rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-500 px-6 text-left')
                            }>Customs<br /><span className='text-xs'>(Save Details to enable)</span>
                            </Tab>}



                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                                <div>
                                    <h2 className='text-slate-700 font-bold text-xl'>{productData.name}</h2>
                                    <p className='text-s text-gray-700 mb-2'><span className='font-bold'>Total: </span>
                                        {UKPriceFormat(productTotal ? productTotal : "0.00")}
                                    </p>
                                </div>
                            </div>
                            <form onSubmit={onSubmitHandler} className="flex w-full flex-wrap justify-between">
                                {!productData && <Loader />}
                                {productData && FormElements.map((item, index) => (
                                    <div key={index} className={item.className ? item.className : 'w-full'}>
                                        <FormElement
                                            elementLabel={item.elementLabel}
                                            elementName={item.elementName}
                                            elementType={item.elementType}
                                            required={item.required}
                                            //elementValue={productData[item.elementName] !== "" ? productData[item.elementName] : item.elementValue}
                                            elementValue={productData[item.elementName]}
                                            elementOptions={item.elementOptions}
                                            fileDeleteHandler={fileDeleteHandler}
                                            fileRemoveHandler={fileRemoveHandler}
                                            fileReorderHandler={fileReorderHandler}
                                            disabled={item.disabled}
                                            editorValue={editorValues[item.elementName]}
                                            onEditorChange={(newValue, editor) => {
                                                setEditorValues((prevState) => {
                                                    return {
                                                        ...prevState, [editor.id]: newValue
                                                    }
                                                });
                                            }}
                                            onBlurHandler={(e)=>{
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }
                                            }}
                                            onChangeHandler={(e) => {
                                                setProductData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        [item.elementName]: e.target.value
                                                    }
                                                });
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }else{
                                                    e.target.classList.remove("bg-red-50");
                                                }

                                                if (e.target.id === "markup") {
                                                    setMarkup(e.target.value);
                                                }

                                                if (e.target.id === "transport_markup") {
                                                    setTransportMarkup(e.target.value);
                                                }
                                            }} />
                                    </div>
                                ))}
                                <Dropzone fileUploadHandler={fileUploadHandler} />
                            </form>
                        </Tab.Panel>
                        <Tab.Panel className={'min-h-[800px]'}>
                            <div className="w-full">
                                <Items productId={productId} setItemsTotal={setComponentsTotal} itemType="MAT" setItemsStatus={setComponentsStatus} itemsStatus={componentsStatus} markup={markup} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className={'min-h-[800px]'}>
                            <div className="w-full">
                                <Items productId={productId} setItemsTotal={setFabricsTotal} itemType="FAB" setItemsStatus={setFabricsStatus} itemsStatus={fabricsStatus} markup={transportMarkup} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className={'min-h-[800px]'}>
                            <div className="w-full">
                                <Items productId={productId} setItemsTotal={setFinishesTotal} itemType="FIN" setItemsStatus={setFinishesStatus} itemsStatus={finishesStatus} markup={transportMarkup} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className={'min-h-[800px]'}>
                            <div className="w-full">
                                <Items productId={productId} setItemsTotal={setTransportTotal} itemType="TRA" setItemsStatus={setTransportStatus} itemsStatus={transportStatus} markup={transportMarkup} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className={'min-h-[800px]'}>
                            <div className="w-full">
                                <Items productId={productId} setItemsTotal={setCourierTotal} itemType="COU" setItemsStatus={setCourierStatus} itemsStatus={courierStatus} markup={transportMarkup} />
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className={'min-h-[800px]'}>
                            <div className="w-full">
                                <Items productId={productId} setItemsTotal={setCustomsTotal} itemType="CUS" setItemsStatus={setCustomsStatus} itemsStatus={customsStatus} markup={transportMarkup} />
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            }
            {props.productType === "extra" &&
                <>
                    <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                        <div>
                            <h2 className='text-slate-700 font-bold text-xl'>{productData.name}</h2>
                        </div>
                    </div>
                    <form onSubmit={onSubmitHandler} className="flex w-full flex-wrap justify-between">
                        {!productData && <Loader />}
                        {productData && FormElementsExtra.map((item, index) => (
                            <div key={index} className={item.className ? item.className : 'w-full'}>
                                <FormElement
                                    elementLabel={item.elementLabel}
                                    elementName={item.elementName}
                                    elementType={item.elementType}
                                    elementValue={productData[item.elementName]}
                                    elementOptions={item.elementOptions}
                                    fileDeleteHandler={fileDeleteHandler}
                                    fileRemoveHandler={fileRemoveHandler}
                                    fileReorderHandler={fileReorderHandler}
                                    disabled={item.disabled}
                                    editorValue={editorValues[item.elementName]}
                                    onEditorChange={(newValue, editor) => {
                                        setEditorValues((prevState) => {
                                            return {
                                                ...prevState, [editor.id]: newValue
                                            }
                                        });
                                    }}
                                    onChangeHandler={(e) => {
                                        setProductData((prevState) => {
                                            return {
                                                ...prevState,
                                                [item.elementName]: e.target.value
                                            }
                                        })
                                        if (e.target.id === "quantity") {
                                            setExtraQty(e.target.value);
                                        }
                                        if (e.target.id === "unit_price") {
                                            setExtraUnitPrice(e.target.value);
                                        }
                                    }} />
                            </div>
                        ))}
                        <Dropzone fileUploadHandler={fileUploadHandler} />
                        <div className='w-full mb-8 pt-8 border-b border-gray-200'></div>
                        <div className="flex items-center justify-between w-full">
                            <button className="btn flex items-center" type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                </svg>
                                <span className='ml-2'>Save</span>
                            </button>
                            {productId !== "" && <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                                e.preventDefault();
                                setIsOpen2(true);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>

                                <span className='ml-2'>Delete</span>
                            </button>}
                        </div>
                    </form>
                </>
            }
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        props.onDeleteHandler(productId);
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default EditProductForm