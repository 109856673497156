import React from 'react'
import { Link } from 'react-router-dom';
import Logo from "../images/RHA-admin-logo.svg";

function Sidebar(props) {

    const icons = {

        quotes: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
        </svg>,

        orders: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
        </svg>,

        invoices: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>,

        products: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
        </svg>,

        customers: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
        </svg>,

        suppliers: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" /></svg>,

        logout: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
        </svg>
    }

    return (
        <div className='w-60 bg-slate-600 h-full'>
            <div className='text-white font-bold p-6 pt-8 text-2xl'><img className='block w-[80%] m-auto' src={Logo} alt="RHA Logo" /></div>
            <ul className='p-6'>
                <li className='flex items-center border-b border-t border-slate-500 pb-2 pt-2 mt-2'>
                    <Link to='/projects' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "projects" ? "text-blue-200 font-bold" : ""}`}>{icons.quotes}<span className='ml-4'>Quotes</span></Link></li>
                <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/projects/order' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "orders" ? "text-blue-200 font-bold" : ""}`}>{icons.orders}<span className='ml-4'>Orders</span></Link></li>
                {/* <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/quotes' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "quotes" ? "text-slate-300 font-bold" : ""}`}>{icons.quotes}<span className='ml-4'>Quotes</span></Link></li> */}
                {/* <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/invoices' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "orders" ? "text-slate-300 font-bold" : ""}`}>{icons.orders}<span className='ml-4'>Orders</span></Link></li> */}
                {/* <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/invoices' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "invoices" ? "text-slate-300 font-bold" : ""}`}>{icons.invoices}<span className='ml-4'>Invoices</span></Link></li> */}
                <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/customers' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "customers" ? "text-blue-200 font-bold" : ""}`}>{icons.customers}<span className='ml-4'>Customers</span></Link></li>
                {/* <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/products' className={`py-2 flex items-center text-white text-sm uppercase ${props.activeItem == "products" ? "text-slate-300 font-bold" : ""}`}>{icons.products}<span className='ml-4'>Products</span></Link></li> */}
                <li className='flex items-center border-b border-slate-500 pb-2 pt-2'>
                    <Link to='/suppliers' className={`py-2 flex items-center uppercase text-sm text-white ${props.activeItem == "suppliers" ? "text-blue-200 font-bold" : ""}`}>{icons.suppliers}<span className='ml-4'>Suppliers</span></Link></li>
                <li className='flex items-center border-b border-slate-500 pb-2 mt-2'>
                    <Link to="/logout" className='py-2 flex items-center text-white text-sm uppercase'>
                        {icons.logout}<span className='ml-4'>Logout</span></Link></li>
            </ul>
        </div>
    )
}

export default Sidebar