import axios from "axios";
import * as configuration from "../config"

export function getQuotes(access_token, project_id) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_quotes&view=quotes&apikey=${access_token}&project_id=${project_id}`
    };

    return axios(config)
        .then(function (response) {
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getQuotesSuppliers(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getQuote(access_token, id) {
    
    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/quotes/quotes/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}



export function addQuote(access_token, quoteData) {

    var data = quoteData;

    var config = {
        method: 'post',
        url: `${configuration.baseUrl}/api/index.php/v1/quotes/quotes`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            return JSON.stringify(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

}

//
export function addQuoteImage(access_token, files) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i])
    }

    return axios.post(`${configuration.baseUrl}/index.php/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               return console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

}
//

//
export function deleteQuoteImage(access_token, filename) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    formData.append("filename", filename)

    return axios.post(`${configuration.baseUrl}/index.php/delete`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               return console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

}
//

export function editQuote(access_token, quoteData, id) {

    var data = JSON.stringify(quoteData);

    var config = {
        method: 'patch',
        url: `${configuration.baseUrl}/api/index.php/v1/quotes/quotes/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return JSON.stringify(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

}

export function deleteQuote(access_token, id) {
    
    var config = {
        method: 'delete',
        url: `${configuration.baseUrl}/api/index.php/v1/quotes/quotes/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}
