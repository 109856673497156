import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 'auto',
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function Dropzone(props) {
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => thumbnails.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const thumbs = thumbnails.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  const onDrop = useCallback(acceptedFiles => {
    const renamedAcceptedFiles = acceptedFiles.map((file) => (
      new File([file], `${uuidv4()}.${file.name.split('.').reverse()[0]}`, { type: file.type })
    ))
    props.fileUploadHandler(renamedAcceptedFiles);
    renamedAcceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        if (file.name.split('.').reverse()[0] != "pdf" && file.name.split('.').reverse()[0] != "csv") {
          setThumbnails(thumbnails => [...thumbnails, { preview: URL.createObjectURL(file) }]);
        }
      }
    });
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div className='flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100' {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p className='block text-gray-500 text-s mb-1'>Drop files here, or click to select files.</p> :
            <p className='block text-gray-500 text-s mb-1'>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </>
  )
}

export default Dropzone;