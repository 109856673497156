import { useEffect, useMemo, useState, createContext } from 'react'
import * as api from '../api/QuotesApi'
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from '../components/Modal';
import Table from '../components/Table';

import AddQuoteForm from '../components/Quotes/AddQuoteForm';
import EditQuoteForm from '../components/Quotes/EditQuoteForm';
import Loader from '../components/Loader';
import Sidebar from '../components/Sidebar';
import * as configuration from "../config";
import NoImage from "../images/RHA-admin-no-image.svg";

export const QuotesContext = createContext();

const Image = (props) => {
  try {
    const imagesArr = props.files.split(",");
    if (!Array.isArray(imagesArr)) {
      throw new Error("is not array");
    }
    return (
      <div className='w-[100px]'><img src={imagesArr[0] != "" ? `${configuration.baseUrl}/images/${imagesArr[0]}` : NoImage}
        onError={e => {
          e.currentTarget.src = NoImage;
        }}
      /></div>
    )
  } catch (e) {
    //console.log(e.message);
    return <></>;
  }
}


function Quotes(props) {

  const apikey = sessionStorage.getItem('apikey');
  const [quotes, setQuotes] = useState([]);
  const [data, setData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [apiFinished, setApiFinished] = useState(false);

  const [editSelect, setEditSelect] = useState('');
  const [deleteSelect, setDeleteSelect] = useState('');

  const [quoteId, setQuoteId] = useState('');

  const [selectedModal, setSelectedModal] = useState('');

  const [currentPage, setCurrentPage] = useState(0);


  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    if (editSelect !== '') {
      setSelectedModal("edit");
      setQuoteId(editSelect);
      setIsOpen(true);
      setEditSelect('');
    }
  }, [editSelect]);

  useEffect(() => {
    if (deleteSelect !== '') {
      api.deleteQuote(apikey, deleteSelect).then(() => {
        setApiFinished(false);
        setRefresh(!refresh);
      })
    }
  }, [deleteSelect])

  useEffect(() => {
    if (apikey) {
      api.getQuotes(apikey, state.project_id)
        .then((res) => {
          if (res) {
            setQuotes(res);
            setApiFinished(true);
          }
        });
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (apikey) {
      api.getQuotes(apikey, state.project_id)
        .then((res) => {
          if (res) {
            setQuotes(res);
            setApiFinished(true);
          }
        });
    }
  }, [refresh]);


  useEffect(() => {
    let quoteData = [];
    quotes.map((item, index) => {
      if (item.attributes.state === 1) {
        quoteData.push(
          {
            'id': item.attributes.id,
            'name': item.attributes.name,
            'project_id': item.attributes.project_id
          }
        )
      }
    });
    setData(quoteData);
  }, [quotes])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Project ID',
        accessor: 'project_id',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
    ],
    []
  )

  const addQuoteHandler = () => {
    setSelectedModal('add');
    setIsOpen(true);
  }

  return (
    <>
      {apikey &&
        <QuotesContext.Provider value={{ refresh, setRefresh, isOpen, setIsOpen }}>
          {!apiFinished && <Loader />}
          {quotes && apiFinished &&
            <>
              <div className='flex h-full'>
                <Sidebar activeItem={"quotes"} />
                <div className='grow h-full overflow-auto relative'>
                  <div className='flex p-6 w-full justify-between items-center'><div><h2 className='text-slate-700 font-bold text-2xl'>Quotes</h2></div><button className='btn flex items-center' onClick={addQuoteHandler}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                    <span className='ml-2'>Add Quote</span></button></div>
                  <Table columns={columns} data={data} setEditSelect={setEditSelect} setDeleteSelect={setDeleteSelect} currentPage={currentPage} setCurrentPage={setCurrentPage} hiddenColumns={['project_id', 'id']} />
                </div>
              </div>
            </>
          }
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} dialogTitle={selectedModal == 'edit' ? 'Edit Quote' : 'Add Quote'} modalSize={"max-w-3xl"}>
            {selectedModal == "edit" && <EditQuoteForm quoteId={quoteId} setDeleteSelect={setDeleteSelect} />}
            {selectedModal == "add" && <AddQuoteForm project_id={state.project_id} />}
          </Modal>
        </QuotesContext.Provider>
      }
    </>
  )
}

export default Quotes