import { useEffect, useMemo, useState, createContext, Children } from 'react'
import * as api from '../api/ProductsApi';
import { getCustomer } from '../api/CustomersApi';
import { getProject, editProject } from '../api/ProjectsApi';
import { useNavigate, useLocation, useParams, Link, json } from 'react-router-dom';
import Modal from '../components/Modal';
import Table from '../components/Table/Table';
import ProductForm from '../components/Products/ProductForm';
import Loader from '../components/Loader';
import Sidebar from '../components/Sidebar';
import * as configuration from "../config";
import NoImage from "../images/RHA-admin-no-image.svg";
import { Menu } from '@headlessui/react';
import * as icons from "../images/imagesSvg";
import { Tab } from '@headlessui/react'
import Areas from '../components/Areas/Areas';
import _ from 'lodash';

import GenerateQuotePdf from '../components/GeneratePdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { UKPriceFormat, addVat, getVat, dateNow } from '../config';
import ContentFrame from '../components/ContentFrame';

import * as svg from "../images/imagesSvg";
import DropDownMenu from '../components/DropDownMenu';

import Toast from '../components/Toast';

export const ProductsContext = createContext();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MyImage = (props) => {
  const imageWidth = props.width;
  try {
    const imagesArr = props.files.split(",");
    if (!Array.isArray(imagesArr)) {
      throw new Error("is not array");
    }
    return (
      <div className={`${imageWidth ? 'w-[' + imageWidth + ']' : 'w-[100px]'}`}><img src={imagesArr[0] != "" ? `${configuration.baseUrl}/images/${imagesArr[0]}` : NoImage}
        onError={e => {
          e.currentTarget.src = NoImage;
        }}
      /></div>
    )
  } catch (e) {
    //console.log(e.message);
    return <></>;
  }
}



const options = [
  { value: "", icon: icons.warnSvg, color: "red" },
  { value: "na", icon: icons.minusSvg, color: "gray" },
  { value: "complete", icon: icons.checkSvg, color: "green" },
  { value: "inprogress", icon: icons.timeSvg, color: "yellow" },
]

const StatusIcon = (props) => {
  return (
    <div className='flex items-center py-1 border-b border-gray-100 text-xs'>
      <div className={`mr-2 rounded-full p-[1px] ${props.itemsStatus ? options[options.findIndex(object => {
        return object.value === props.itemsStatus;
      })].color : options[0].color} text-blue-900`}>
        {props.itemsStatus ? options[options.findIndex(object => {
          return object.value === props.itemsStatus;
        })].icon : options[0].icon}
      </div>
      <div>{props.children}</div>
    </div>
  )
}

const ListItem = (props) => {
  return (
    <div className='flex items-center py-1 border-b border-gray-100 text-xs'>
      <div>{props.children}</div>
    </div>
  )
}

let GlobalStatus = (props) => {
  const [status, setStatus] = useState("");
  useEffect(() => {
    if (
      (props.components_status === "complete" || props.components_status === "na") &&
      (props.fabrics_status === "complete" || props.fabrics_status === "na") &&
      (props.finishes_status === "complete" || props.finishes_status === "na") &&
      (props.transport_status === "complete" || props.transport_status === "na") &&
      (props.courier_status === "complete" || props.courier_status === "na") &&
      (props.customs_status === "complete" || props.customs_status === "na")
    ) {
      setStatus("complete");
    }
    else {
      setStatus("");
    }
  }, [props])


  return (
    <div className={`m-auto inline-block rounded-full p-[1px] ${status ? options[options.findIndex(object => {
      return object.value === status;
    })].color : options[0].color} text-blue-900`}>
      {status ? options[options.findIndex(object => {
        return object.value === status;
      })].icon : options[0].icon}
    </div>
  )

}


function Products() {

  const apikey = sessionStorage.getItem('apikey');
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [apiFinished, setApiFinished] = useState(false);
  const [productId, setProductId] = useState('');
  const [productType, setProductType] = useState('product');
  const [selectedModal, setSelectedModal] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [extrasTotalPrice, setExtrasTotalPrice] = useState(0);
  const [totalPriceNoCommission, setTotalPriceNoCommission] = useState(0);
  const [totalPriceNoMarkup, setTotalPriceNoMarkup] = useState(0);
  const [vat, setVat] = useState(0);
  const [totalPriceWithVat, setTotalPriceWithVat] = useState(0);
  const [sortedIds, setSortedIds] = useState();
  const [customer, setCustomer] = useState({});
  const [projectData, setProjectData] = useState({});
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [productCodes, setProductCodes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [productsGrouped, setProductsGrouped] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [hasCommission, setHasCommission] = useState(false);

  const navigate = useNavigate();

  const params = useParams();

  const location = useLocation();
  const { projectType } = location.state;

  const doToast = (type, title, message, doRefresh) => {
    if (doRefresh) {
      setRefresh(!refresh);
    }
    setToastType(type);
    setToastTitle(title);
    setToastText(message);
    setIsOpenToast(true);
  }

  useEffect(()=>{
      setRefresh(!refresh);
  },[hiddenColumns])

  useEffect(() => {
    getProject(apikey, params.projectId).then((resp) => {
      console.log("ProjectData: ", resp);
      setProjectData({
        "id": resp.attributes.id,
        "state": resp.attributes.state,
        "ordering": resp.attributes.ordering,
        "project_number": resp.attributes.project_number,
        "project_name": resp.attributes.project_name,
        "project_description": resp.attributes.project_description,
        "customer": resp.attributes.customer,
        "files": resp.attributes.files,
        "customer_id": resp.attributes.customer_id,
        "commission": resp.attributes.commission,
        "total": resp.attributes.total,

        "quote_number": resp.attributes.quote_number,
        "order_number": resp.attributes.order_number,
        "revision_number": resp.attributes.revision_number,
        "internal_ref": resp.attributes.internal_ref,
        "external_ref": resp.attributes.external_ref,
        "delivery_details": resp.attributes.delivery_details,
        "settlement_terms": resp.attributes.settlement_terms,
        "quote_date": resp.attributes.quote_date,
        "location_details": resp.attributes.location_details,
        "quote_notes": resp.attributes.quote_notes,
        "quote_issued_by": resp.attributes.quote_issued_by,
        "terms_conditions": resp.attributes.terms_conditions,
        "order_terms": resp.attributes.order_terms,
        "quote_contact_details": resp.attributes.quote_contact_details,
        "invoice_contact_details": resp.attributes.invoice_contact_details,
        "order_contact_details": resp.attributes.order_contact_details,
        "quote_to": resp.attributes.quote_to,
        "invoice_to": resp.attributes.invoice_to,
        "order_to": resp.attributes.order_to,
        "order_settlement_terms": resp.attributes.order_settlement_terms,
        "order_delivery_details": resp.attributes.order_delivery_details,
        "invoice_issued_by": resp.attributes.invoice_issued_by,
        "order_issued_by": resp.attributes.order_issued_by,
        "invoice_notes": resp.attributes.invoice_notes,
        "order_notes": resp.attributes.order_notes,
        "invoice_date": resp.attributes.invoice_date,
        "order_date": resp.attributes.order_date
      });
      setAreas(resp.attributes.areas ? JSON.parse('[' + resp.attributes.areas + ']') : []);
      console.log(resp.attributes.commission);
      setHiddenColumns(resp.attributes.commission == null ? ['supplier', 'total_prices'] : ['supplier']);
      setHasCommission(resp.attributes.commission == null ? false : true);
    });
  }, []);

  const openEditView = (itemId) => {
    if (itemId !== '') {
      setSelectedModal("edit");
      setProductId(itemId);
      setIsOpen(true);
    }
  }

  const onDeleteHandler = (itemId) => {
    if (itemId !== '') {
      setApiFinished(false);
      api.deleteProduct(apikey, itemId).then((resp) => {
        doToast("success", "Success", "Entry deleted", true);
        setApiFinished(true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
        setApiFinished(true);
      });
    }
  }

  const onDuplicateHandler = (itemId) => {
    setApiFinished(false);
    if (itemId !== '') {
      api.duplicateProduct(apikey, itemId).then((resp) => {
        doToast("success", "Success", "Entry duplicated", true);
        api.getProducts(apikey)
          .then((res) => {
            if (res) {
              setProducts(res);
              setApiFinished(true);
            } else {
              setProducts([]);
              setApiFinished(true)
            }
          });
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }

  const reOrderHandler = () => {
    let items_order = _.map(sortedIds, 'id').toString();
    api.orderProducts(apikey, items_order).then(() => {
      doToast("success", "Success", "Items reordered", true);
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }

  const onChangeExportHandler = (itemId, value) => {

    api.editProduct(apikey, { export: value }, itemId).then((resp) => {
      doToast("success", "Success", "Export Setting Saved", true);
      api.getProducts(apikey)
        .then((res) => {
          setProducts(res);
        });
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }

  const onChangeExportHandlerAll = (exportValue) => {
    api.exportProducts(apikey, exportValue, params.projectId).then((resp) => {
      doToast("success", "Success", "Export Settings Saved", true);
      api.getProducts(apikey)
        .then((res) => {
          setProducts(res);
        });
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }

  useEffect(() => {
    if (apikey) {
      api.getProducts(apikey, params.projectId, productType)
        .then((res) => {
          if (res) {
            setProducts(res);
            setApiFinished(true);
          }
        });
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setProductId("");
    if (apikey) {
      api.getProducts(apikey, params.projectId, productType)
        .then((res) => {
          if (res) {
            console.log(res);
            setProducts(res);
          } else {
            setProducts([]);
            setApiFinished(true);
          }
        });
    }
  }, [refresh]);


  useEffect(() => {
    let productData = [];
    let extraData = [];
    let totalPrice = 0;
    let extrasTotalPrice = 0;
    let totalPriceNoCommission = 0;
    let totalPriceNoMarkup = 0;
    let prodCodes = [];
    let dataForExport = [];

    products.map((item, index) => {
      if (item.attributes.state === 1) {

        let imageFiles = [];

        // if (item.attributes.code.trim() != "") {
        //   prodCodes.push(item.attributes.code)
        // }

        if (item.attributes.files != "") {
          let files = item.attributes.files.split(",");
          console.log("imageFiles: ", files);
          files.map((file) => {
            imageFiles.push(configuration.checkIsImage(file));
          });
        }

        const calcTotal = configuration.calcTotal(
          +item.attributes.quantity,
          +item.attributes.fabrics_total,
          +item.attributes.components_total,
          +item.attributes.finishes_total,
          +item.attributes.transport_total,
          +item.attributes.courier_total,
          +item.attributes.customs_total,
          +item.attributes.markup,
          +item.attributes.transport_markup,
          +projectData.commission
        )

        const productObj = {
          "id": item.attributes.id,
          "name": item.attributes.name,
          "export": item.attributes.export,
          "description": item.attributes.description,
          "supplier": item.attributes.supplier,
          'image': <MyImage files={item.attributes.files} />,
          'imageFiles': imageFiles,
          "quantity": item.attributes.quantity,
          "width": item.attributes.width,
          "length": item.attributes.length,
          "height": item.attributes.height,
          "weight_net": item.attributes.weight_net,
          "weight_gross": item.attributes.weight_gross,
          "project_id": item.attributes.project_id,
          "unit_price": UKPriceFormat(
            //calcTotal.unitSubtotalWithCommission
            calcTotal.unitTotalWithCommissionAndTransport
          ),
          "unit_price_no_commission": UKPriceFormat(
            //calcTotal.unitSubtotalWithCommission
            calcTotal.unitTotalWithNoCommissionAndTransport
          ),
          "total": UKPriceFormat(calcTotal.fullTotalWithCommissionAndTransport),
          "total_no_commission": UKPriceFormat(calcTotal.fullTotalWithNoCommissionAndTransport),
          "total_prices": <>
            <ListItem>Unit Price:<br /> {UKPriceFormat(calcTotal.unitTotalWithCommissionAndTransport)}</ListItem>
            <ListItem>Total Price:<br /> {UKPriceFormat(calcTotal.fullTotalWithCommissionAndTransport)}</ListItem>
          </>
          ,
          "total_without_commission": <>
            <ListItem>Unit Price:<br /> {UKPriceFormat(calcTotal.unitTotalWithNoCommissionAndTransport)}</ListItem>
            <ListItem>Total Price:<br /> {UKPriceFormat(calcTotal.fullTotalWithNoCommissionAndTransport)}</ListItem>
          </>,
          "fabrics_total": UKPriceFormat(item.attributes.fabrics_total),
          "components_total": UKPriceFormat(item.attributes.components_total),
          "finishes_total": UKPriceFormat(item.attributes.finishes_total),
          "transport_total": UKPriceFormat(item.attributes.transport_total),
          "courier_total": UKPriceFormat(item.attributes.courier_total),
          "customs_total": UKPriceFormat(item.attributes.customs_total),
          "breakdown": <>
            {item.attributes.components_status !== "na" && <StatusIcon itemsStatus={item.attributes.components_status}>Components: {UKPriceFormat(item.attributes.components_total)}</StatusIcon>}
            {item.attributes.fabrics_status !== "na" && <StatusIcon itemsStatus={item.attributes.fabrics_status}>Fabrics: {UKPriceFormat(item.attributes.fabrics_total)}</StatusIcon>}
            {item.attributes.finishes_status !== "na" && <StatusIcon itemsStatus={item.attributes.finishes_status}>Finishes: {UKPriceFormat(item.attributes.finishes_total)}</StatusIcon>}
            {item.attributes.transport_status !== "na" && <StatusIcon itemsStatus={item.attributes.transport_status}>Transport: {UKPriceFormat(item.attributes.transport_total)}</StatusIcon>}
            {item.attributes.courier_status !== "na" && <StatusIcon itemsStatus={item.attributes.courier_status}>Courier: {UKPriceFormat(item.attributes.courier_total)}</StatusIcon>}
            {item.attributes.customs_status !== "na" && <StatusIcon itemsStatus={item.attributes.customs_status}>Customs: {UKPriceFormat(item.attributes.customs_total)}</StatusIcon>}
          </>,
          "product_cost": <>
            <ListItem>Unit Price:<br /> {UKPriceFormat(calcTotal.unitTotalMarkedUpRounded)}</ListItem>
            <ListItem>Total Price:<br /> {UKPriceFormat(calcTotal.itemsTotalMarkedUpRounded)}</ListItem>
          </>,
          "product_cost_transport": <>
            <ListItem>Unit Price:<br /> {UKPriceFormat(calcTotal.unitTotalTransportMarkedUpRounded)}</ListItem>
            <ListItem>Total Price:<br /> {UKPriceFormat(calcTotal.itemsTotalTransportMarkedUpRounded)}</ListItem>
          </>,
          "status": <GlobalStatus
            components_status={item.attributes.components_status}
            fabrics_status={item.attributes.fabrics_status}
            finishes_status={item.attributes.finishes_status}
            transport_status={item.attributes.transport_status}
            courier_status={item.attributes.courier_status}
            customs_status={item.attributes.customs_status}
          />,
          "fabrics_status": item.attributes.fabrics_status,
          "components_status": item.attributes.components_status,
          "finishes_status": item.attributes.finishes_status,
          "transport_status": item.attributes.transport_status,
          "courier_status": item.attributes.courier_status,
          "customs_status": item.attributes.customs_status,
          "files": item.attributes.files,
          "first_file": item.attributes.first_file,
          //"b64File": item.attributes.b64File,
          "code": item.attributes.code,
          "discounts": item.attributes.discounts,
          "projectCommission": projectData.commission,
          "product_type": item.attributes.product_type,
          "extra_total": UKPriceFormat(item.attributes.extra_total),
          "area": item.attributes.area
        }

        if (item.attributes.product_type !== "extra") {
          productData.push(productObj)
        } else {
          extraData.push({ ...productObj, "unit_price": UKPriceFormat(item.attributes.extra_total / item.attributes.quantity) });
        }

        if (item.attributes.export !== "false") {
          extrasTotalPrice += (+item.attributes.extra_total)
          totalPrice += calcTotal.fullTotalWithCommissionAndTransport;
          totalPriceNoCommission += calcTotal.fullTotalWithNoCommissionAndTransport;
          totalPriceNoMarkup += calcTotal.fullTotalWithCommissionAndTransport;
          if (item.attributes.product_type !== "extra") {
            dataForExport.push(productObj);
          }
        }
      }
    });
    setTotalPrice(totalPrice);
    setTotalPriceNoCommission(totalPriceNoCommission);
    setTotalPriceNoMarkup(totalPriceNoMarkup);
    setVat(getVat(totalPrice, 20));
    setTotalPriceWithVat(addVat(totalPrice, 20));
    setData(productData);
    setExtraData(extraData);
    setExtrasTotalPrice(extrasTotalPrice);
    setProductCodes(prodCodes);
    setExportData(dataForExport);
    //setProductsGrouped(_.groupBy(a, function(b) { return b.area}))

    setProductsGrouped(
      _.chain(exportData)
        // Group the elements of Array based on `color` property
        .groupBy("area")
        // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => ({ area: key, products: value }))
        .value()
    );
  }, [products, projectData]);

  useEffect(() => {
    if (totalPrice) {
      editProject(apikey, { "total": parseFloat(totalPrice).toFixed(2) }, params.projectId).then(() => {
        doToast("success", "Success", "Entry total saved", true);
      }).catch((resp) => {
        doToast("error", "Error", resp.message);
      });
    }
  }, [totalPrice])

  const saveAreasHandler = () => {
    const areasStr = JSON.stringify(areas).slice(1, -1);
    editProject(apikey, { "areas": areasStr }, params.projectId).then(() => {
      doToast("success", "Success", "Entry areas saved", true);
    }).catch((resp) => {
      doToast("error", "Error", resp.message);
    });
  }


  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Image',
        accessor: 'image',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Export',
        accessor: 'export',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) => (
          <div dangerouslySetInnerHTML={{ __html: value }}></div>
        ),
      },
      {
        Header: 'Supplier',
        accessor: 'supplier',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Area',
        accessor: 'area',
      },
      {
        Header: 'Breakdown / Unit Cost',
        accessor: 'breakdown',
        //   Cell: ({ value, row }) => (
        //     <div dangerouslySetInnerHTML={{__html: value}}></div>
        // ),
      },
      {
        Header: 'Product',
        accessor: 'product_cost',
      },
      {
        Header: 'Transport',
        accessor: 'product_cost_transport',
      },
      {
        Header: 'Total',
        accessor: 'total_without_commission',
      },
      {
        Header: 'Total (Commission)',
        accessor: 'total_prices',
      },
    ],
    []
  )

  const columnsExtra = useMemo(
    () => [
      {
        Header: 'Qty',
        accessor: 'quantity',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Image',
        accessor: 'image',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) => (
          <div dangerouslySetInnerHTML={{ __html: value }}></div>
        ),
      },
      {
        Header: 'Supplier',
        accessor: 'supplier',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Unit Price',
        accessor: 'unit_price',
      },
      {
        Header: 'Total',
        accessor: 'extra_total',
      },
    ],
    []
  )

  const addProductHandler = (e) => {
    e.preventDefault();
    setSelectedModal('add');
    setIsOpen(true);
  }

  return (
    <>
      {apikey &&
        <ProductsContext.Provider value={{ refresh, setRefresh, isOpen, setIsOpen, productCodes, areas }}>
          {!apiFinished && <Loader />}
          {products && apiFinished && data && hiddenColumns.length &&
            <>
              <div className='flex h-full'>
                <Sidebar activeItem={"products"} />
                <ContentFrame>
                  <div className='w-full pl-6 pr-6 pb-4 pt-4 bg-slate-100'>
                    <Link className='text-xs font-bold text-gray-700 flex items-center mr-4' to="/projects">
                      {svg.chevronBackSvg}
                      <span className='ml-2'>Back to {projectType === "order" ? "Orders" : "Quotes"}</span></Link>
                  </div>
                  <div className='p-20 w-full flex space-between border-b border-slate-200 pb-6 pt-6'>
                    <div className='pr-[10%] w-2/3'>
                      <p className='text-s text-gray-700'><span className='font-bold'>Project Number:</span> {projectData.project_number}</p>
                      <h1 className='text-slate-700 font-bold text-2xl mb-2'>{projectData.project_name}</h1>
                      <p className='text-s text-gray-700 mb-2'><span className='font-bold'>Customer:</span> {projectData.customer}</p>
                      {hasCommission && <p className='text-s text-gray-700 mb-2'><span className='font-bold'>Commission:</span> {projectData.commission}%</p>}
                      <span className='text-xs font-bold text-gray-700'>Description:</span><br />
                      <div className='text-xs text-gray-700 mt-2 project-description' dangerouslySetInnerHTML={{ __html: projectData.project_description }}></div>
                      <p className='text-xl text-gray-700 mt-4'><span className='font-bold'>Total:</span> {UKPriceFormat(totalPriceNoCommission)}</p>
                      {hasCommission && <p className='text-xl text-gray-700 mt-4'><span className='font-bold'>Total <small>(commission)</small>:</span> {UKPriceFormat(totalPrice)}</p>}
                    </div>
                  </div>
                  <div className='w-full'>
                    <Tab.Group
                      onChange={(index) => {
                        if (index === 0) { setProductType("product") }
                        if (index === 1) { setProductType("extra") }
                      }} >
                      <Tab.List className="flex space-x-2 bg-slate-100 py-2 px-6 mb-2">

                        <Tab className={({ selected }) =>
                          classNames(
                            'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                            selected
                              ? 'bg-white text-gray-700'
                              : 'hover:bg-white/[0.8]'
                          )
                        }><span className='text-sm'>Products</span><br /><span className='text-xs'>Total: {UKPriceFormat(totalPrice)}</span>
                        </Tab>
                        <Tab className={({ selected }) =>
                          classNames(
                            'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                            selected
                              ? 'bg-white text-gray-700'
                              : 'hover:bg-white/[0.8]'
                          )
                        }><span className='text-sm'>Additional Items</span><br /><span className='text-xs'>Total: {UKPriceFormat(extrasTotalPrice)}</span>
                        </Tab>
                        <Tab className={({ selected }) =>
                          classNames(
                            'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                            selected
                              ? 'bg-white text-gray-700'
                              : 'hover:bg-white/[0.8]'
                          )
                        }><span className='text-sm'>Areas</span><br /><span className='text-xs'>{`${areas.map((area) => area.name)}`}</span>
                        </Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          <div className='flex p-6 w-full justify-between items-center'>
                            <div>
                              <h2 className='text-slate-700 font-bold text-xl'>Items</h2>
                            </div>
                            <button className='btn flex items-center ml-auto mr-4' onClick={addProductHandler}>
                              {svg.addSvg}
                              <span className='ml-2'>Add Product</span>
                            </button>
                            <button className='btn flex items-center mr-4' onClick={reOrderHandler}>
                              {svg.reOrderSvg}
                              <span className='ml-2'>Save Items Ordering</span>
                            </button>
                            <DropDownMenu
                              projectData={projectData}
                              //products={data}
                              products={exportData}
                              extras={extraData}
                              total={totalPrice + extrasTotalPrice}
                              totalNoCommission={totalPriceNoCommission + extrasTotalPrice}
                              totalNoMarkup={totalPriceNoMarkup}
                              areas={areas}
                              productsGrouped={productsGrouped}
                              projectType={projectType}
                              hasCommission={hasCommission}
                            />
                          </div>
                          <Table columns={columns} data={data} setSortedIds={setSortedIds} setData={setData} openEditView={openEditView} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} onChangeExportHandler={onChangeExportHandler} onChangeExportHandlerAll={onChangeExportHandlerAll} currentPage={currentPage} setCurrentPage={setCurrentPage} tableType="products" hiddenColumns={hiddenColumns} />
                        </Tab.Panel>
                        <Tab.Panel>
                          <div className='flex p-6 w-full justify-between items-center'>
                            <div>
                              <h2 className='text-slate-700 font-bold text-xl'>Items</h2>
                            </div>
                            <button className='btn flex items-center ml-auto mr-4' onClick={addProductHandler}>
                              {svg.addSvg}
                              <span className='ml-2'>Add Additional Item</span>
                            </button>
                            <DropDownMenu
                              projectData={projectData}
                              //products={data}
                              products={exportData}
                              extras={extraData}
                              total={totalPrice + extrasTotalPrice}
                              totalNoCommission={totalPriceNoCommission + extrasTotalPrice}
                              totalNoMarkup={totalPriceNoMarkup}
                              areas={areas}
                              productsGrouped={productsGrouped}
                              projectType={projectType}
                              hasCommission={hasCommission}
                            />
                          </div>
                          <Table columns={columnsExtra} data={extraData} setSortedIds={setSortedIds} setData={setExtraData} openEditView={openEditView} onDeleteHandler={onDeleteHandler} onDuplicateHandler={onDuplicateHandler} currentPage={currentPage} setCurrentPage={setCurrentPage} tableType="extras" hiddenColumns={['id', 'supplier']} />
                        </Tab.Panel>
                        <Tab.Panel>
                          <div className='p-6'>
                            <Areas areas={areas} setAreas={setAreas} saveAreasHandler={saveAreasHandler} />
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </ContentFrame>
              </div>
            </>
          }
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} setRefresh={setRefresh} refresh={refresh} dialogTitle={selectedModal == 'edit' ? `Edit ${productType === "product" ? "Product" : "Additional Item"}` : `Add ${productType === "product" ? "Product" : "Additional Item"}`} modalSize={productType === "product" ? "w-full" : "max-w-3xl"}>
            <ProductForm productId={productId} project_id={params.projectId} selectedModal={selectedModal} setSelectedModal={setSelectedModal} onDeleteHandler={onDeleteHandler} productType={productType} onDuplicateHandler={onDuplicateHandler} />
          </Modal>
          <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </ProductsContext.Provider>
      }
    </>
  )
}

export default Products