import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Logo from "../images/RHALogo.jpg";
import * as configuration from "../config";
import Html from 'react-pdf-html';

const stylesheet = {
  // clear margins for all <p> tags
  p: {
    margin: 0,
    marginBottom: "8pt",
    fontSize: "8pt",
  },
  em: {
    fontFamily: "Helvetica-Oblique",
    fontStyle: "italic"
  },
  strong: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold"
  },
  "em strong": {
    fontFamily: "Helvetica-BoldOblique",
    fontStyle: "italic",
    fontWeight: "bold"
  },
};

function GenerateOrderPdf(props) {

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.header} fixed>
          <View style={styles.headerAddress}>
            <Text style={[styles.headerAddressText, { fontWeight: "bold" }]}>RHA Furniture</Text>
            <Text style={styles.headerAddressText}>1 Brentwaters, The Ham, London, TW8 8HQ, UK</Text>
          </View>
          <View style={styles.logo}><Image src={Logo} styles={styles.logoImage} /></View>
        </View>
        <View>
          <Text style={{fontWeight: "bold", fontSize: "14pt", marginBottom: "10pt"}}>Order</Text>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Order No:</Text><Text>{props.projectData.project_number}</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Ref:</Text><Text>{props.projectData.project_name}</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Acc. No:</Text><Text>123456</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Date:</Text><Text>{props.projectData.date}</Text></View>
          <View style={styles.detailsRow}><Text style={{ fontSize: "8pt" }}>N.B the order number and reference must be quoted on all correspondence</Text></View>
        </View>
        <View style={styles.divider}></View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "50%", paddingRight: "10pt" }}>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>To:</Text></View>
            {props.projectData.name && <View style={styles.detailsRow}><Text>{props.projectData.name}</Text></View>}
            {props.projectData.billing_addressline1 && <View style={styles.detailsRow}><Text>{props.projectData.billing_addressline1}</Text></View>}
            {props.projectData.billing_addressline2 && <View style={styles.detailsRow}><Text>{props.projectData.billing_addressline2}</Text></View>}
            {props.projectData.billing_addressline3 && <View style={styles.detailsRow}><Text>{props.projectData.billing_addressline3}</Text></View>}
            {props.projectData.billing_addressline4 && <View style={styles.detailsRow}><Text>{props.projectData.billing_addressline4}</Text></View>}
            {props.projectData.billing_locality && <View style={styles.detailsRow}><Text>{props.projectData.billing_locality}</Text></View>}
            {props.projectData.billing_region && <View style={styles.detailsRow}><Text>{props.projectData.billing_region}</Text></View>}
            {props.projectData.billing_country && <View style={styles.detailsRow}><Text>{props.projectData.billing_country}</Text></View>}
            {props.projectData.billing_postcode && <View style={styles.detailsRow}><Text>{props.projectData.billing_postcode}</Text></View>}
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>VAT Number:</Text><Text>{props.projectData.taxnumber}</Text></View>
          </View>
          <View style={{ width: "50%", paddingLeft: "10pt" }}>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "100%" }]}>Delivery to/work to be carried out at:</Text></View>
            <View>
              <Text>Ex-works</Text>
              <Text>Please contact collection with logistics@rhafurniture.com</Text>
            </View>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "50%", paddingRight: "10pt" }}>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>FAO:</Text><Text>{props.projectData.contactname}</Text></View>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>Email:</Text><Text>{props.projectData.email}</Text></View>
            <View style={styles.detailsRow}><Text style={styles.detailLabel}>Tel No:</Text><Text>{props.projectData.phone}</Text></View>
          </View>
          <View style={{ width: "50%", paddingLeft: "10pt" }}>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Settlement Terms:</Text><Text>30 Days</Text></View>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Collection date:</Text><Text>Mid Decemeber 2022</Text></View>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Location on site:</Text><Text>Location</Text></View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.rowHeader}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text>Qty</Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text>Code</Text></View>
            <View style={[styles.cell, { 'width': "30%" }]}><Text>Description</Text></View>
            <View style={[styles.cell, { 'width': "30%" }]}><Text>Design Intent</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>Unit Price</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>Total</Text></View>
          </View>
          {props.products.map((item, key) => {

            //const cellCount = Object.keys(item.fields).length;
            // const cellWidth = 100 / cellCount;
            // const cellWidthStr = cellWidth + "%";

            return (
              <View style={styles.row} key={key} wrap={false}>
                <View style={[styles.cell, { 'width': "6%" }]}><Text>{item.quantity}</Text></View>
                <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.code}</Text></View>
                <View style={[styles.cell, { 'width': "30%" }]}>
                  <Html stylesheet={stylesheet}>{`${item.description}`}</Html>
                  </View>
                <View style={[styles.cell, { 'width': "30%", display: 'flex', flexDirection: 'row', flexWrap: "wrap" }]}>
                  {item.imageFiles.map((image, index)=> <Image style={{width: index === 0 ? "100%" : "49%", padding: "2pt", boxSizing: "border-box"}} src={configuration.baseUrl+"/images/"+image} />)}
                </View>
                <View style={[styles.cell, { 'width': "12%" }]}><Text>{item.unit_price}</Text></View>
                <View style={[styles.cell, { 'width': "12%" }]}><Text>{item.total}</Text></View>
              </View>
            )
          })}
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>Delivery and Installation for ground floor only between normal working hours (Monday to Friday 9am-5pm) NOT INCLUDED</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>TBC</Text></View>
          </View>
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>any customs and duties charges to receipt of fabric will be invoiced back to the client </Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>-</Text></View>
          </View>
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>Net Total</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.total}</Text></View>
          </View>
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>VAT @ 20%</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.vat}</Text></View>
          </View>
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>Total Order Value</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text></Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.totalWithVat}</Text></View>
          </View>
        </View>

        <View style={styles.footer} fixed>
          <View style={{ width: "80%" }}>
            <Text>Registered Address: 1 Brentwaters, The Ham, London, TW8 8HQ, UK</Text>
            <Text>Registered in England. Registered number: 4311457</Text>
          </View>
          <View style={{ width: "20%" }}>
            <Text style={{ textAlign: "right" }} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} />
          </View>
        </View>
      </Page>
    </Document>
  )
};

Font.register({
  family: 'Helvetica', fonts: [
    { src: "Helvetica" },
    { src: "Helvetica-Bold", fontWeight: 'bold' },
    { src: "Helvetica-Oblique", fontStyle: 'italic' },
    { src: "Helvetica-BoldOblique", fontStyle: 'italic', fontWeight: 'bold' },
  ]
});

const styles = StyleSheet.create({
  body: {
    padding: "30pt",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "8pt",
    position: "relative",
    paddingBottom: "80pt",
    width: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "20pt",
    marginBottom: "20pt",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
  },
  headerAddress: {
    width: "35%"
  },
  headerAddressText: {
    marginBottom: "2pt"
  },
  logo: {
    width: "25%",
  },
  logoImage: {
    display: "block",
    width: "100%",
    height: "auto"
  },
  detailsRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "4pt"
  },
  detailLabel: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    width: "60pt",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold"
  },
  table: {
    width: "100%",
    marginTop: "10pt",
    borderTopWidth: "1px",
    borderTopColor: "#666",
    borderTopStyle: "solid",
    // borderRightWidth: "1px",
    // borderRightColor: "#999",
    // borderRightStyle: "solid",
    boxSizing: "border-box",
  },
  rowHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
    backgroundColor: "#DDDDDD"
  },
  rowFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
    backgroundColor: "#F6F6F6"
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
  },
  cell: {
    padding: "10pt",
    borderLeftWidth: "1px",
    borderLeftColor: "#999",
    borderLeftStyle: "solid",
    boxSizing: "border-box"
  },
  divider: {
    paddingTop: "10pt",
    marginBottom: "10pt",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    boxSizing: "border-box",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: "30px",
    color: "#000",
    paddingVertical: 20,
    fontSize: "8pt",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxSizing: "border-box",
    borderTopWidth: "1px",
    borderTopColor: "#DDD",
    borderTopStyle: "solid",
  }
});

//ReactPDF.render(<Quixote />);

export default GenerateOrderPdf