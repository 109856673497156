import axios from "axios";
import * as configuration from "../config"

// export function getProducts(access_token) {

//     var config = {
//         method: 'get',
//         url: `${configuration.baseUrl}/api/index.php/v1/products/products?page[offset]=0&page[limit]=500`,
//         headers: {
//             'Authorization': `Bearer ${access_token}`
//         }
//     };

//     return axios(config)
//         .then(function (response) {
//             //console.log(response.data.data);
//             return response.data.data;
//         })
//         .catch(function (error) {
//             console.log(error);
//         });
// }

export function getProducts(access_token, project_id, product_type) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/index.php?option=com_products&view=products&apikey=${access_token}&project_id=${project_id}&product_type=${product_type}`
    };

    return axios(config)
        .then(function (response) {
            //console.log("Products:", response.data.data)
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getProductsSuppliers(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/suppliers/suppliers`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getProduct(access_token, id) {
    
    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/products/products/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            console.log("getProductAPi: ",response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}



export function addProduct(access_token, productData) {

    var data = productData;

    var config = {
        method: 'post',
        url: `${configuration.baseUrl}/api/index.php/v1/products/products`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

//
export function addProductImage(access_token, files) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i])
    }

    return axios.post(`${configuration.baseUrl}/index.php/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               //return console.log(JSON.stringify(response.data));
               return response.data;
            })
            .catch(function (error) {
                console.log(error);
                throw new Error(error.message);
            });

}
//

//
export function deleteProductImage(access_token, filename) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    formData.append("filename", filename)

    return axios.post(`${configuration.baseUrl}/index.php/delete`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               //return console.log(JSON.stringify(response.data));
               return response.data;
            })
            .catch(function (error) {
                console.log(error);
                throw new Error(error.message);
            });

}
//

export function editProduct(access_token, productData, id) {

    var data = JSON.stringify(productData);

    var config = {
        method: 'patch',
        url: `${configuration.baseUrl}/api/index.php/v1/products/products/${id}`,
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            // return JSON.stringify(response.data);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            //return error.response.data
            throw new Error(error.response.data.errors[0].title);
        });

}

export function deleteProduct(access_token, product_id) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_products&view=delete&apikey=${access_token}&project_id=${product_id}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log("ERRRR: ", error.response.data);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

export function duplicateProduct(access_token, product_id) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_products&view=duplicate&apikey=${access_token}&product_id=${product_id}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data.data;
        })
        .catch(function (error) {
            console.log("ERRRR: ", error.response.data);
            //return error.response.data;
            throw new Error(error.response.data.errors[0].title);
        });

}

//
export function getImageBuffer(access_token, files) {

    let formData = new FormData();
    formData.append("apikey", access_token);
    formData.append("files", files)

    return axios.post(`${configuration.baseUrl}/index.php/imagebuffer`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(function (response) {
               return JSON.parse(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

}
//

export function getCurrency(access_token) {

    var config = {
        method: 'get',
        url: `${configuration.baseUrl}/api/index.php/v1/currency/currencies`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    };

    return axios(config)
        .then(function (response) {
            //console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function orderProducts(access_token, items_order) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_products&view=order&apikey=${access_token}&items_order=${items_order}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            //return error.response.data;
            throw new Error(error.message);
        });

}

export function exportProducts(access_token, exportValue, projectId) {

    let config = {
        method: 'post',
        url: `${configuration.baseUrl}/index.php?option=com_products&view=export&apikey=${access_token}&export_value=${exportValue}&project_id=${projectId}`
    };

    return axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            return response.data;
        })
        .catch(function (error) {
            //return error.response.data;
            throw new Error(error.message);
        });

}