import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "./DragHandle";

export const DraggableTableRow = ({ row, tableType }) => {
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging
    } = useSortable({
        id: row.original.id
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition
    };

    return (
        <tr ref={setNodeRef} style={style} className="table_row" {...row.getRowProps()}>
            {isDragging ? (
                <td colSpan={row.cells.length} className='p-4'><div className='w-full block overflow-auto'>&nbsp;</div></td>
            ) : (
                row.cells.map((cell, i) => {
                    if (i === 0) {
                        return (
                            <td {...cell.getCellProps()} className='p-4'>
                                <div className='w-full overflow-auto relative flex items-center'>
                                    {tableType !== "customers" && tableType !== "suppliers" && <DragHandle {...attributes} {...listeners} />}
                                    {cell.render("Cell")}
                                </div>
                            </td>
                        );
                    }
                    return (
                        <td {...cell.getCellProps()} className='p-4'>
                            <div className='w-full max-w-[200px] max-h-[245px] block overflow-auto'>
                                {cell.render("Cell")}
                            </div>
                        </td>
                    );
                })
            )}
        </tr>
    );
    
};
