import React, { useState, useContext, useEffect } from 'react'
import * as api from '../../api/SuppliersApi';
import { SuppliersContext } from '../../pages/Suppliers'
import FormElement from '../FormElement';
import Modal from '../Modal';
import Loader from '../Loader';
import Toast from '../Toast';
import Dropzone from '../Dropzone';
import { parse } from "papaparse";
import _ from "lodash";

function SupplierForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const [supplierId, setSupplierId] = useState(props.supplierId);

    const { refresh, setRefresh, isOpen, setIsOpen } = useContext(SuppliersContext);

    const [isOpen2, setIsOpen2] = useState(false);
    const [supplierCategories, setSupplierCategories] = useState({});

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const [showCSVForm, setShowCSVForm] = useState(false);

    const [supplierData, setSupplierData] = useState({
        "name": '',
        "contactname": '',
        "code": '',
        "addressline1": '',
        "addressline2": '',
        "addressline3": '',
        "addressline4": '',
        "locality": '',
        "region": '',
        "postcode": '',
        "country": '',
        "phone": '',
        "email": '',
        "taxnumber": '',
        "created_by": userid,
        "modified_by": userid,
    });

    const [supplierCodes, setSupplierCodes] = useState([]);

    useEffect(() => {
        api.getSuppliers(apikey).then((resp) => {
            setSupplierCodes(resp.map((item) => {
                if(item.attributes.id !== supplierId){
                    return item.attributes.code;
                }
            }));
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }, []);

    const doToast = (type, title, message, doRefresh) => {
        if (doRefresh) {
            setRefresh(!refresh);
        }
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }

    useEffect(() => {
        api.getSuppliersCategories(apikey).then((resp) => {
            let supplierCategoryData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    supplierCategoryData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'code': item.attributes.code,
                        }
                    )
                }
            });
            setSupplierCategories(supplierCategoryData);
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }, []);


    useEffect(() => {
        if (props.selectedModal === "edit") {
            api.getSupplier(apikey, props.supplierId).then((resp) => {
                console.log(resp);
                setSupplierData({
                    "name": resp.attributes.name,
                    "contactname": resp.attributes.contactname,
                    "code": resp.attributes.code,
                    "category": resp.attributes.category,
                    "addressline1": resp.attributes.addressline1,
                    "addressline2": resp.attributes.addressline2,
                    "addressline3": resp.attributes.addressline3,
                    "addressline4": resp.attributes.addressline4,
                    "locality": resp.attributes.locality,
                    "region": resp.attributes.region,
                    "postcode": resp.attributes.postcode,
                    "country": resp.attributes.country,
                    "phone": resp.attributes.phone,
                    "email": resp.attributes.email,
                    "taxnumber": resp.attributes.taxnumber,
                    "modified_by": userid,
                });
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        }
    }, [])


    const onSubmitHandler = (e, submitType) => {
        e.preventDefault();
        if (supplierCodes.includes(supplierData.code)) {
            doToast("error", "Error", "Supplier Code must be unique and not empty");
            return;
        }
        if (props.selectedModal === "edit") {
            api.editSupplier(apikey, supplierData, props.supplierId).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        } else {
            api.addSupplier(apikey, supplierData).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                props.setSelectedModal("edit");
                setSupplierId(resp.attributes.id);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });

        }
    }

    const fileUploadHandler = (data) => {
        data[0].text().then((resp) => {
            const csvData = _.last(parse(resp, { header: true }).data);
            setSupplierData({ ...csvData, id: "", modified_by: userid, created_by: userid });
            setShowCSVForm(false);
            doToast("success", "Success", "CSV Parsed");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const FormElements = [
        { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Contact Name", elementName: "contactname", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Code*", elementName: "code", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Category", elementName: "category", elementType: "select", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Address Line 1", elementName: "addressline1", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 2", elementName: "addressline2", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 3", elementName: "addressline3", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 4", elementName: "addressline4", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Locality", elementName: "locality", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Region", elementName: "region", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Postcode", elementName: "postcode", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Country", elementName: "country", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Phone", elementName: "phone", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Email", elementName: "email", elementType: "email", className: "w-[49%]" },
        { elementLabel: "Tax Number", elementName: "taxnumber", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },

    ]

    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
                <button className="btn flex items-center" onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save</span>
                </button>
                <button className={`btn flex items-center ml-2`} onClick={(e) => {
                    onSubmitHandler(e, "save_and_close");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                {props.selectedModal === "add" && <button className={`btn flex items-center ml-2 mr-auto`} onClick={(e) => {
                    setShowCSVForm(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                    </svg>


                    <span className='ml-2'>Import CSV</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn flex items-center ml-2 mr-auto" onClick={(e) => {
                    e.preventDefault();
                    props.onDuplicateHandler(supplierId);
                    setIsOpen(false);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>


                    <span className='ml-2'>Duplicate</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                    e.preventDefault();
                    setIsOpen2(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                    <span className='ml-2'>Delete</span>
                </button>}
            </div>
            {props.selectedModal === "add" && showCSVForm && <div className='importCSVForm'>
                <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
                <h3 className='block text-gray-500 text-xs font-bold uppercase mb-1'>Upload CSV File</h3>
                <Dropzone fileUploadHandler={fileUploadHandler} />
            </div>}
            <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            <form className="flex w-full flex-wrap justify-between">
                {!supplierData && <Loader />}
                {supplierData && FormElements.map((item, index) => (
                    <div key={index} className={item.className ? item.className : 'w-full'}>
                        <FormElement
                            elementLabel={item.elementLabel}
                            elementName={item.elementName}
                            elementType={item.elementType}
                            required={item.required}
                            elementValue={supplierData[item.elementName]}
                            elementOptions={supplierCategories}
                            onBlurHandler={(e) => {
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                }
                            }}
                            onChangeHandler={(e) => {
                                setSupplierData((prevState) => {
                                    return {
                                        ...prevState,
                                        [item.elementName]: e.target.value
                                    }
                                })
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                } else {
                                    e.target.classList.remove("bg-red-50");
                                }
                            }} />
                    </div>
                ))}
            </form>
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        props.onDeleteHandler(supplierId)
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default SupplierForm