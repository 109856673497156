import logo from './logo.svg';
import './App.css';
import PDFFile from './components/PDFFile';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SupplierMenu from './components/SupplierMenu';
import useStore from './store';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';


import Suppliers from './pages/Suppliers'
import Customers from './pages/Customers';
import Products from './pages/Products';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Projects from './pages/Projects';
import Quotes from './pages/Quotes';

function App() {

  let supplierState = useStore((state) => state.supplier);
  const [apikey, setApikey] = useState(sessionStorage.getItem('apikey'));
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<Logout />} />
          <Route path="/" element={<Login />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:projectType" element={<Projects />} />
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/products/:projectId" element={<Products />} />
          <Route path="/quotes" element={<Quotes />} />
        </Routes>
      </Router>
      {/* <div className='max-w-4xl bg-slate-50 p-5 m-auto mt-20'>
        <h3 className="font-bold mb-4">
          Select a supplier
        </h3>
        <div className='mb-4'><SupplierMenu /></div>
        <PDFDownloadLink document={<PDFFile
          supplierName={supplierState?.fields?.Name}
          supplierAddress={supplierState?.fields?.Address}
          supplierTaxCode={supplierState?.fields?.TaxCode}
        />} fileName="TESTFILE">
          {({ loading }) => loading ? (<span>Loading...</span>) : (<button className='btn'>Download</button>)}
        </PDFDownloadLink>
      </div> */}
    </div>


  );
}

export default App;


