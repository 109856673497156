import React, { useState, useContext, useEffect, useRef } from 'react'
import * as api from '../../api/CustomersApi';
import { CustomersContext } from '../../pages/Customers'
import FormElement from '../FormElement';
import Modal from '../Modal';
import Loader from '../Loader';
import Toast from '../Toast';
import Dropzone from '../Dropzone';
import { parse } from "papaparse";
import _ from "lodash";

function CustomerForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const [customerId, setCustomerId] = useState(props.customerId);

    const { refresh, setRefresh, isOpen, setIsOpen } = useContext(CustomersContext);

    const [isOpen2, setIsOpen2] = useState(false);

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const [showCSVForm, setShowCSVForm] = useState(false);

    const [customerData, setCustomerData] = useState({
        "name": '',
        "contactname": '',
        "code": '',
        "billing_addressline1": '',
        "billing_addressline2": '',
        "billing_addressline3": '',
        "billing_addressline4": '',
        "billing_locality": '',
        "billing_region": '',
        "billing_postcode": '',
        "billing_country": '',
        "shipping_addressline1": '',
        "shipping_addressline2": '',
        "shipping_addressline3": '',
        "shipping_addressline4": '',
        "shipping_locality": '',
        "shipping_region": '',
        "shipping_postcode": '',
        "shipping_country": '',
        "phone": '',
        "email": '',
        "taxnumber": '',
        "created_by": userid,
        "modified_by": userid,
    });

    const [customerCodes, setCustomerCodes] = useState([]);

    useEffect(() => {
        api.getCustomers(apikey).then((resp) => {
            setCustomerCodes(resp.map((item) => {
                if(item.attributes.id !== customerId){
                    return item.attributes.code;
                }
            }));
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }, []);

    const doToast = (type, title, message, doRefresh) => {
        if (doRefresh) {
            setRefresh(!refresh);
        }
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }


    useEffect(() => {
        if (props.selectedModal === "edit") {
            api.getCustomer(apikey, props.customerId).then((resp) => {
                console.log(resp);
                setCustomerData({
                    "name": resp.attributes.name,
                    "contactname": resp.attributes.contactname,
                    "code": resp.attributes.code,
                    "billing_addressline1": resp.attributes.billing_addressline1,
                    "billing_addressline2": resp.attributes.billing_addressline2,
                    "billing_addressline3": resp.attributes.billing_addressline3,
                    "billing_addressline4": resp.attributes.billing_addressline4,
                    "billing_locality": resp.attributes.billing_locality,
                    "billing_region": resp.attributes.billing_region,
                    "billing_postcode": resp.attributes.billing_postcode,
                    "billing_country": resp.attributes.billing_country,
                    "shipping_addressline1": resp.attributes.shipping_addressline1,
                    "shipping_addressline2": resp.attributes.shipping_addressline2,
                    "shipping_addressline3": resp.attributes.shipping_addressline3,
                    "shipping_addressline4": resp.attributes.shipping_addressline4,
                    "shipping_locality": resp.attributes.shipping_locality,
                    "shipping_region": resp.attributes.shipping_region,
                    "shipping_postcode": resp.attributes.shipping_postcode,
                    "shipping_country": resp.attributes.shipping_country,
                    "phone": resp.attributes.phone,
                    "email": resp.attributes.email,
                    "taxnumber": resp.attributes.taxnumber
                });
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        }
    }, [])

    const shipping_addressline1Ref = useRef();
    const shipping_addressline2Ref = useRef();
    const shipping_addressline3Ref = useRef();
    const shipping_addressline4Ref = useRef();
    const shipping_localityRef = useRef();
    const shipping_regionRef = useRef();
    const shipping_postcodeRef = useRef();
    const shipping_countryRef = useRef();

    const onSubmitHandler = (e, submitType) => {
        e.preventDefault();
        if (customerCodes.includes(customerData.code)) {
            doToast("error", "Error", "Customer Code must be unique and not empty");
            return;
        }
        if (props.selectedModal === "edit") {
            api.editCustomer(apikey, customerData, customerId).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        } else {
            api.addCustomer(apikey, customerData).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                props.setSelectedModal("edit");
                setCustomerId(resp.attributes.id);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        }
    }

    const onUpdateShippingHandler = (e) => {
        e.preventDefault();
        shipping_addressline1Ref.current.value = customerData.billing_addressline1;
        shipping_addressline2Ref.current.value = customerData.billing_addressline2;
        shipping_addressline3Ref.current.value = customerData.billing_addressline3;
        shipping_addressline4Ref.current.value = customerData.billing_addressline4;
        shipping_localityRef.current.value = customerData.billing_locality;
        shipping_regionRef.current.value = customerData.billing_region;
        shipping_postcodeRef.current.value = customerData.billing_postcode;
        shipping_countryRef.current.value = customerData.billing_country;
        setCustomerData((prevState) => {
            return {
                ...prevState,
                shipping_addressline1: customerData.billing_addressline1,
                shipping_addressline2: customerData.billing_addressline2,
                shipping_addressline3: customerData.billing_addressline3,
                shipping_addressline4: customerData.billing_addressline4,
                shipping_locality: customerData.billing_locality,
                shipping_region: customerData.billing_region,
                shipping_postcode: customerData.billing_postcode,
                shipping_country: customerData.billing_country,
            }
        });
    }

    const fileUploadHandler = (data) => {
        data[0].text().then((resp) => {
            const csvData = _.last(parse(resp, { header: true }).data);
            setCustomerData({ ...csvData, id: "", modified_by: userid, created_by: userid });
            setShowCSVForm(false);
            doToast("success", "Success", "CSV Parsed");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const FormElements = [
        { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Contact/s (Comma Separated)", elementName: "contactname", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Phone", elementName: "phone", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Email/s (Comma Separated)", elementName: "email", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Code*", elementName: "code", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Tax Number", elementName: "taxnumber", elementType: "text", className: "w-[49%]" },

        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },

        { elementLabel: "Billing Address", elementType: "title", className: "font-medium text-lg w-full mb-6 text-gray-900" },
        { elementLabel: "Address Line 1", elementName: "billing_addressline1", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 2", elementName: "billing_addressline2", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 3", elementName: "billing_addressline3", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 4", elementName: "billing_addressline4", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Locality", elementName: "billing_locality", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Region", elementName: "billing_region", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Postcode", elementName: "billing_postcode", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Country", elementName: "billing_country", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Shipping Address", elementType: "title", className: "font-medium text-lg w-[50%] mb-6 text-gray-900" },
        { elementLabel: "Make same as Billing", elementType: "button", elementAction: onUpdateShippingHandler, className: "font-medium text-right text-lg w-[50%] mb-6 text-gray-900" },
        { elementLabel: "Address Line 1", elementName: "shipping_addressline1", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline1Ref },
        { elementLabel: "Address Line 2", elementName: "shipping_addressline2", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline2Ref },
        { elementLabel: "Address Line 3", elementName: "shipping_addressline3", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline3Ref },
        { elementLabel: "Address Line 4", elementName: "shipping_addressline4", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline4Ref },
        { elementLabel: "Locality", elementName: "shipping_locality", elementType: "text", className: "w-[49%]", elementRef: shipping_localityRef },
        { elementLabel: "Region", elementName: "shipping_region", elementType: "text", className: "w-[49%]", elementRef: shipping_regionRef },
        { elementLabel: "Postcode", elementName: "shipping_postcode", elementType: "text", className: "w-[49%]", elementRef: shipping_postcodeRef },
        { elementLabel: "Country", elementName: "shipping_country", elementType: "text", className: "w-[49%]", elementRef: shipping_countryRef }
    ]

    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
                <button className="btn flex items-center" onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save</span>
                </button>
                <button className={`btn flex items-center ml-2`} onClick={(e) => {
                    onSubmitHandler(e, "save_and_close");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                {props.selectedModal === "add" && <button className={`btn flex items-center ml-2 mr-auto`} onClick={(e) => {
                    setShowCSVForm(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                    </svg>


                    <span className='ml-2'>Import CSV</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn flex items-center ml-2 mr-auto" onClick={(e) => {
                    e.preventDefault();
                    props.onDuplicateHandler(customerId);
                    setIsOpen(false);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>


                    <span className='ml-2'>Duplicate</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                    e.preventDefault();
                    setIsOpen2(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                    <span className='ml-2'>Delete</span>
                </button>}
            </div>
            {props.selectedModal === "add" && showCSVForm && <div className='importCSVForm'>
                <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
                <h3 className='block text-gray-500 text-xs font-bold uppercase mb-1'>Upload CSV File</h3>
                <Dropzone fileUploadHandler={fileUploadHandler} />
            </div>}
            <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            <form className="flex w-full flex-wrap justify-between">
                {!customerData && <Loader />}
                {customerData && FormElements.map((item, index) => (
                    <div key={index} className={item.className ? item.className : 'w-full'}>
                        <FormElement
                            ref={item.elementRef}
                            elementLabel={item.elementLabel}
                            elementName={item.elementName}
                            elementType={item.elementType}
                            required={item.required}
                            elementValue={customerData[item.elementName]}
                            elementAction={item.elementAction}
                            onBlurHandler={(e) => {
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                }
                            }}
                            onChangeHandler={(e) => {
                                setCustomerData((prevState) => {
                                    return {
                                        ...prevState,
                                        [item.elementName]: e.target.value
                                    }
                                })
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                } else {
                                    e.target.classList.remove("bg-red-50");
                                }
                            }} />
                    </div>
                ))}
            </form>
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        props.onDeleteHandler(customerId)
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default CustomerForm