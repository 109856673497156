import React, { useState, useEffect } from 'react'
import * as api from '../../api/SuppliersApi';
import FormElement from '../FormElement';
import Toast from '../Toast';

function ProductComponentSupplierForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const [supplierCategories, setSupplierCategories] = useState({});
    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const [supplierData, setSupplierData] = useState({
        "name": '',
        "contactname": '',
        "code": '',
        "addressline1": '',
        "addressline2": '',
        "addressline3": '',
        "addressline4": '',
        "locality": '',
        "region": '',
        "postcode": '',
        "country": '',
        "phone": '',
        "email": '',
        "taxnumber": '',
        "created_by": userid,
        "modified_by": userid,
    });

    const [supplierCodes, setSupplierCodes] = useState([]);

    useEffect(() => {
        api.getSuppliers(apikey).then((resp) => {
            setSupplierCodes(resp.map((item) => {
                return item.attributes.code;
            }));
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }, []);

    const doToast = (type, title, message) => {
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }

    useEffect(() => {
        api.getSuppliersCategories(apikey).then((resp) => {
            let supplierCategoryData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    supplierCategoryData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'code': item.attributes.code,
                        }
                    )
                }
            });
            setSupplierCategories(supplierCategoryData);
        });
    }, []);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (supplierCodes.includes(supplierData.code)) {
            doToast("error", "Error", "Supplier Code must be unique and not empty");
            return;
        }
        api.addSupplier(apikey, supplierData).then((resp) => {
            doToast("success", "Success", "Entry saved", true);
            props.setIsOpen(false);
            props.setSuppliers((prevState) => [...prevState, {
                'id': resp.attributes.id,
                'name': resp.attributes.name,
                'code': resp.attributes.code,
            }]);
            props.setSupplier(resp.attributes.name);
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const FormElements = [
        { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Contact Name", elementName: "contactname", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Code*", elementName: "code", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Category", elementName: "category", elementType: "select", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Address Line 1", elementName: "addressline1", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 2", elementName: "addressline2", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 3", elementName: "addressline3", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 4", elementName: "addressline4", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Locality", elementName: "locality", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Region", elementName: "region", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Postcode", elementName: "postcode", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Country", elementName: "country", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Phone", elementName: "phone", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Email", elementName: "email", elementType: "email", className: "w-[49%]" },
        { elementLabel: "Tax Number", elementName: "taxnumber", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },

    ]

    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
            <button className={`btn flex items-center ml-2`} onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                </div>
                <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            <form className="flex w-full flex-wrap justify-between">
                {FormElements.map((item, index) => (
                    <div key={index} className={item.className ? item.className : 'w-full'}>
                        <FormElement
                            elementLabel={item.elementLabel}
                            elementName={item.elementName}
                            elementType={item.elementType}
                            required={item.required}
                            elementValue={supplierData[item.elementName]}
                            elementOptions={supplierCategories}
                            onBlurHandler={(e) => {
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                }
                            }}
                            onChangeHandler={(e) => {
                                setSupplierData((prevState) => {
                                    return {
                                        ...prevState,
                                        [item.elementName]: e.target.value
                                    }
                                })
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                } else {
                                    e.target.classList.remove("bg-red-50");
                                }
                            }} />
                    </div>
                ))}
            </form>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default ProductComponentSupplierForm