import React, { useState, useEffect, useRef } from 'react'
import * as api from '../../api/CustomersApi';
import FormElement from '../FormElement';
import Toast from '../Toast';

function ProjectCustomerForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const [customerData, setCustomerData] = useState({
        "name": '',
        "contactname": '',
        "code": '',
        "billing_addressline1": '',
        "billing_addressline2": '',
        "billing_addressline3": '',
        "billing_addressline4": '',
        "billing_locality": '',
        "billing_region": '',
        "billing_postcode": '',
        "billing_country": '',
        "shipping_addressline1": '',
        "shipping_addressline2": '',
        "shipping_addressline3": '',
        "shipping_addressline4": '',
        "shipping_locality": '',
        "shipping_region": '',
        "shipping_postcode": '',
        "shipping_country": '',
        "phone": '',
        "email": '',
        "taxnumber": '',
        "created_by": userid,
        "modified_by": userid,
    });

    const [customerCodes, setCustomerCodes] = useState([]);

    useEffect(() => {
        api.getCustomers(apikey).then((resp) => {
            setCustomerCodes(resp.map((item) => {
                return item.attributes.code;
            }));
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }, []);

    const doToast = (type, title, message) => {
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }

    const shipping_addressline1Ref = useRef();
    const shipping_addressline2Ref = useRef();
    const shipping_addressline3Ref = useRef();
    const shipping_addressline4Ref = useRef();
    const shipping_localityRef = useRef();
    const shipping_regionRef = useRef();
    const shipping_postcodeRef = useRef();
    const shipping_countryRef = useRef();

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (customerCodes.includes(customerData.code)) {
            doToast("error", "Error", "Customer Code must be unique and not empty");
            return;
        }
        api.addCustomer(apikey, customerData).then((resp) => {
            doToast("success", "Success", "Entry saved", true);
            props.setIsOpen(false);
            props.setProjectCustomers((prevState) => [...prevState, {
                'id': resp.attributes.id,
                'name': resp.attributes.name,
                'code': resp.attributes.code,
            }]);
            props.onChangeCustomerHandler(resp.attributes.id);
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const onUpdateShippingHandler = (e) => {
        e.preventDefault();
        shipping_addressline1Ref.current.value = customerData.billing_addressline1;
        shipping_addressline2Ref.current.value = customerData.billing_addressline2;
        shipping_addressline3Ref.current.value = customerData.billing_addressline3;
        shipping_addressline4Ref.current.value = customerData.billing_addressline4;
        shipping_localityRef.current.value = customerData.billing_locality;
        shipping_regionRef.current.value = customerData.billing_region;
        shipping_postcodeRef.current.value = customerData.billing_postcode;
        shipping_countryRef.current.value = customerData.billing_country;
        setCustomerData((prevState) => {
            return {
                ...prevState,
                shipping_addressline1: customerData.billing_addressline1,
                shipping_addressline2: customerData.billing_addressline2,
                shipping_addressline3: customerData.billing_addressline3,
                shipping_addressline4: customerData.billing_addressline4,
                shipping_locality: customerData.billing_locality,
                shipping_region: customerData.billing_region,
                shipping_postcode: customerData.billing_postcode,
                shipping_country: customerData.billing_country,
            }
        });
    }

    const FormElements = [
        { elementLabel: "Name*", elementName: "name", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Contact/s (Comma Separated)", elementName: "contactname", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Phone", elementName: "phone", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Email/s (Comma Separated)", elementName: "email", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Code*", elementName: "code", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Tax Number", elementName: "taxnumber", elementType: "text", className: "w-[49%]" },

        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },

        { elementLabel: "Billing Address", elementType: "title", className: "font-medium text-lg w-full mb-6 text-gray-900" },
        { elementLabel: "Address Line 1", elementName: "billing_addressline1", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 2", elementName: "billing_addressline2", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 3", elementName: "billing_addressline3", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Address Line 4", elementName: "billing_addressline4", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Locality", elementName: "billing_locality", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Region", elementName: "billing_region", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Postcode", elementName: "billing_postcode", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Country", elementName: "billing_country", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Shipping Address", elementType: "title", className: "font-medium text-lg w-[50%] mb-6 text-gray-900" },
        { elementLabel: "Make same as Billing", elementType: "button", elementAction: onUpdateShippingHandler, className: "font-medium text-right text-lg w-[50%] mb-6 text-gray-900" },
        { elementLabel: "Address Line 1", elementName: "shipping_addressline1", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline1Ref },
        { elementLabel: "Address Line 2", elementName: "shipping_addressline2", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline2Ref },
        { elementLabel: "Address Line 3", elementName: "shipping_addressline3", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline3Ref },
        { elementLabel: "Address Line 4", elementName: "shipping_addressline4", elementType: "text", className: "w-[49%]", elementRef: shipping_addressline4Ref },
        { elementLabel: "Locality", elementName: "shipping_locality", elementType: "text", className: "w-[49%]", elementRef: shipping_localityRef },
        { elementLabel: "Region", elementName: "shipping_region", elementType: "text", className: "w-[49%]", elementRef: shipping_regionRef },
        { elementLabel: "Postcode", elementName: "shipping_postcode", elementType: "text", className: "w-[49%]", elementRef: shipping_postcodeRef },
        { elementLabel: "Country", elementName: "shipping_country", elementType: "text", className: "w-[49%]", elementRef: shipping_countryRef }
    ]

    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
            <button className={`btn flex items-center ml-2`} onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                </div>
                <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            <form className="flex w-full flex-wrap justify-between">
                {FormElements.map((item, index) => (
                    <div key={index} className={item.className ? item.className : 'w-full'}>
                        <FormElement
                            ref={item.elementRef}
                            elementLabel={item.elementLabel}
                            elementName={item.elementName}
                            elementType={item.elementType}
                            required={item.required}
                            elementValue={customerData[item.elementName]}
                            elementAction={item.elementAction}
                            onBlurHandler={(e) => {
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                }
                            }}
                            onChangeHandler={(e) => {
                                setCustomerData((prevState) => {
                                    return {
                                        ...prevState,
                                        [item.elementName]: e.target.value
                                    }
                                })
                                if (e.target.required && e.target.value === "") {
                                    e.target.classList.add("bg-red-50");
                                } else {
                                    e.target.classList.remove("bg-red-50");
                                }
                            }} />
                    </div>
                ))}
            </form>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default ProjectCustomerForm