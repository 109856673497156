import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'

function Modal(props) {

    function closeModal() {
        props.setIsOpen(false);
        props.setRefresh(!props.refresh);
    }

    function openModal() {
        props.setIsOpen(true);
        props.setRefresh(!props.refresh);
    }


    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className={`w-full transform overflow-hidden rounded-xl bg-white p-8 text-left align-middle shadow-xl transition-all ${props.modalSize}`}>
                                    <button onClick={closeModal} className='absolute right-3 top-3'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg></button>
                                    {props.dialogTitle && <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-medium leading-6 text-gray-900 pb-6 border-b mb-6"
                                    >
                                        {props.dialogTitle}
                                    </Dialog.Title>}
                                    <div className="mt-2">
                                        {props.children}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Modal