import axios from "axios";
import * as configuration from "../config";

export function login(username, password) {

  var qs = require('qs');
  var data = qs.stringify({
    'username': username,
    'password': password
  });

  var config = {
    method: 'post',
    url: `${configuration.baseUrl}/index.php`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      //console.log('apiKey', response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error)
      throw new Error(`An error as occured. ${error.code}`);
    });

}

export function getUser(access_token, userid) {

  var config = {
    method: 'get',
    url: `${configuration.baseUrl}/api/index.php/v1/users/${userid}`,
    headers: {
      'Authorization': `Bearer ${access_token}`
    }
  };

  return axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
      throw new Error(`An error as occured. ${error.code}`);
    });
}
