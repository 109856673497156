const devUrl = "http://localhost/rha_backend_v1";
const prodUrl = "https://rha-admin.htcstaging.space";

export const baseUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? devUrl : prodUrl;

export const UKPriceFormat = (price) => new Intl.NumberFormat(
  'en-GB', // BCP 47 language tag 
  {
    style: 'currency', // we want a currency
    currency: 'GBP' // ISO 4217 currency code
  }
).format(price);

export const currencyFormat = (price, currencyType) => new Intl.NumberFormat(
  'en-GB', // BCP 47 language tag 
  {
    style: 'currency', // we want a currency
    currency: currencyType ? currencyType : 'GBP' // ISO 4217 currency code
  }
).format(price);

export const addVat = (price, percent) => {
  return price + price / 100 * percent;
}

export const getVat = (price, percent) => {
  return price / 100 * percent;
}

export const dateNow = () => {
  const date = new Date();

  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  let currentDate = `${day}/${month}/${year}`;
  return currentDate;
}

export const calcTotal = (quantity, fabrics_total, components_total, finishes_total, transport_total, courier_total, customs_total, markup, transport_markup, commission) => {

  // remove markup
  markup = 0;
  transport_markup = 0;
  //-

  let unitTotal = (
    parseFloat(fabrics_total) +
    parseFloat(components_total) +
    parseFloat(finishes_total) +
    parseFloat(customs_total)
  );

  let unitTotalMarkedUp = unitTotal + unitTotal * markup / 100;
  let unitTotalMarkedUpRounded = Math.ceil(unitTotalMarkedUp);
  let itemsTotalMarkedUp = unitTotalMarkedUp * quantity;
  let itemsTotalMarkedUpRounded = Math.ceil(+itemsTotalMarkedUp);

  // console.log(unitTotal);
  // console.log(unitTotalMarkedUp);
  // console.log(itemsTotalMarkedUp);
  // console.log(itemsTotalMarkedUpRounded);

  let unitTotalTransport = (
    parseFloat(transport_total) +
    parseFloat(courier_total)
  );

  let unitTotalTransportMarkedUp = unitTotalTransport + unitTotalTransport * transport_markup / 100;
  //let unitTotalTransportMarkedUpRounded = Math.ceil(unitTotalTransportMarkedUp);
  let unitTotalTransportMarkedUpRounded = unitTotalTransportMarkedUp;
  let itemsTotalTransportMarkedUp = unitTotalTransportMarkedUp * quantity;
  //let itemsTotalTransportMarkedUpRounded = Math.ceil(itemsTotalTransportMarkedUp);
  let itemsTotalTransportMarkedUpRounded = itemsTotalTransportMarkedUp;
  
  let V22 = quantity;
  let W20 = unitTotal;
  let R21 = markup / 100
  let X21 = unitTotalMarkedUpRounded;
  let X26 = unitTotalTransportMarkedUpRounded;
  let R28 = commission / 100;
  let X27 = itemsTotalTransportMarkedUpRounded;

  let X30 = Math.ceil((X21 * R28 + W20) + (X21 * R28 + W20) * R21);
  //let X30_nocommission = Math.ceil((X21));
  let X30_nocommission = Math.ceil((X21 * 0 + W20) + (X21 * 0 + W20) * R21);

  let X31 = X30 * V22;
  let X32 = X30 + X26;

  let X31_nocommission = (X30_nocommission * V22);
  let X32_nocommission = (X30_nocommission + X26);
  
  let unitSubtotalWithCommission = X30;
  let totalSubtotalWithCommission = X31;

  let unitSubtotalNoCommission = X30_nocommission;
  let totalSubtotalNoCommission = X31_nocommission;

  let unitTotalWithCommissionAndTransport = X32;
  let unitTotalWithNoCommissionAndTransport = X32_nocommission;
  //let fullTotalWithCommissionAndTransport = X31 + X27;
  let fullTotalWithCommissionAndTransport = X32 * V22;
  let fullTotalWithNoCommissionAndTransport = X32_nocommission * V22;
  
  // console.log("Unit Subtotal with Commission: ", unitSubtotalWithCommission);
  // console.log("Total Subtotal with Commission: ", totalSubtotalWithCommission);
  // console.log("Unit Total with Commission and Transport: ", unitTotalWithCommissionAndTransport);
  // console.log("Full Total with Commission and Transport: ", fullTotalWithCommissionAndTransport);

  return {
    "itemsTotalMarkedUpRounded": +itemsTotalMarkedUpRounded,
    "unitTotalMarkedUpRounded": +unitTotalMarkedUpRounded,
    "unitTotalTransportMarkedUpRounded": +unitTotalTransportMarkedUpRounded,
    "itemsTotalTransportMarkedUpRounded": +itemsTotalTransportMarkedUpRounded,
    "unitSubtotalWithCommission": unitSubtotalWithCommission,
    "totalSubtotalWithCommission": totalSubtotalWithCommission,
    "unitSubtotalNoCommission": unitSubtotalNoCommission,
    "totalSubtotalNoCommission": totalSubtotalNoCommission,
    "unitTotalWithCommissionAndTransport": unitTotalWithCommissionAndTransport,
    "unitTotalWithNoCommissionAndTransport": unitTotalWithNoCommissionAndTransport,
    "fullTotalWithCommissionAndTransport": fullTotalWithCommissionAndTransport,
    "fullTotalWithNoCommissionAndTransport": fullTotalWithNoCommissionAndTransport,
  };

}


export const calcUnitPrice = (fabrics_total, components_total, finishes_total, transport_total, courier_total, customs_total, markup, transport_markup, commission) => {

  //remove markup
  markup = 0;
  transport_markup = 0;
  //-

  let itemsXquantity = (
    parseFloat(fabrics_total) +
    parseFloat(components_total) +
    parseFloat(finishes_total) +
    parseFloat(customs_total)
  );

  let itemsTransportXquantity = (
    parseFloat(transport_total) +
    parseFloat(courier_total)
  );


  let itemsXquantityXmarkup = itemsXquantity + (itemsXquantity * markup / 100);

  let itemsTransportXquantityXmarkup = itemsTransportXquantity + (itemsTransportXquantity * transport_markup / 100);

  let itemsXquantityXmarkupXcommission = (Math.ceil(itemsXquantityXmarkup) + Math.ceil(itemsTransportXquantityXmarkup)) + ((Math.ceil(itemsXquantityXmarkup) + Math.ceil(itemsTransportXquantityXmarkup)) * parseFloat(commission) / 100);

  return parseFloat(itemsXquantityXmarkupXcommission).toFixed(2);

}

export const checkIsImage = (str) => {
  // Regex to check valid
  // Image File
  let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);

  // if str
  // is empty return false
  if (str == null) {
    return "false";
  }

  // Return true if the str
  // matched the ReGex
  if (regex.test(str) == true) {
    return str;
  }
  else {
    return "false";
  }
}


