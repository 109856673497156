import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const useStore = create(
    //persist(
        devtools(
            (set) => ({
                supplier: {},
                setSupplier: (supplier) => set((state) => ({ supplier: supplier })),
                apikey: {},
                setApikey: (apikey) => set((state) => ({ apikey: apikey })),
                euroExchangeRate: {},
                setEuroExchangeRate: (euroExchangeRate) => set((state) => ({ euroExchangeRate: euroExchangeRate })),
            })),
        // {
        //     name: "session", // unique name
        //     getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
        // }
    //)
);

export default useStore;