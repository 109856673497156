import React, { useState, useContext, useEffect } from 'react'
import * as api from '../../api/QuotesApi';
import { QuotesContext } from '../../pages/Quotes'
import FormElement from '../FormElement';
import Modal from '../Modal';
import Loader from '../Loader';
import Dropzone from '../Dropzone';

function EditQuoteForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const { refresh, setRefresh, isOpen, setIsOpen } = useContext(QuotesContext);

    const [quoteData, setQuoteData] = useState();
    const [isOpen2, setIsOpen2] = useState(false);
    const [quoteSuppliers, setQuoteSuppliers] = useState({});
    const [quotefiles, setQuotefiles] = useState([]);
    const [newQuotefiles, setNewQuotefiles] = useState([]);

    useEffect(() => {
        api.getQuotesSuppliers(apikey).then((resp) => {
            let quoteCategoryData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    quoteCategoryData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'code': item.attributes.code,
                        }
                    )
                }
            });
            setQuoteSuppliers(quoteCategoryData);
        });
    }, []);


    useEffect(() => {
        api.getQuote(apikey, props.quoteId).then((resp) => {
            console.log(resp);
            setQuoteData({
                "name": resp.attributes.name,
                "project_id": resp.attributes.project_id,
                // "supplier": resp.attributes.supplier,
                // "files": resp.attributes.files,
                "created_by": userid,
                "modified_by": userid,
            });
            setQuotefiles(resp.attributes.files.split(","));
        });
    }, [])

    const onSubmitHandler = (e) => {
        e.preventDefault();
        api.editQuote(apikey, quoteData, props.quoteId).then((resp) => {
            setRefresh(!refresh);
            setIsOpen(false);
        });
    }

    useEffect(() => {
        let newProds = quotefiles.concat(newQuotefiles);

        var filtered = newProds.filter(function (el) {
            return el != "";
        });
        
        setQuoteData((prevState) => {
            return {
                ...prevState,
                files: filtered.join()
            }
        });
    }, [newQuotefiles])

    const fileUploadHandler = (data) => {
        let imagesArr = [];
        data.map((item) => {
            imagesArr.push(item.name);
        })
        api.addQuoteImage(apikey, data).then((resp) => {
            setNewQuotefiles(imagesArr);
        });
    }

    const fileDeleteHandler = (imagesArr, filename) => {
        setQuotefiles(imagesArr);
        setNewQuotefiles([]);
        api.deleteQuoteImage(apikey, filename).then((resp) => {
            setQuoteData((prevState) => {
                return {
                    ...prevState,
                    files: imagesArr.join()
                }
            });
        })
    }

    const fileRemoveHandler = (imagesArr) => {
            setQuoteData((prevState) => {
                return {
                    ...prevState,
                    files: imagesArr.join()
                }
            });
    }

    const fileReorderHandler = (imagesArr) => {
        console.log(imagesArr)
        setQuoteData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
}

    const FormElements = [
        { elementLabel: "Name", elementName: "name", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Project ID", elementName: "project_id", elementType: "text", className: "w-[49%]" },
        // { elementLabel: "Description", elementName: "description", elementType: "textarea", className: "w-full" },
        // { elementLabel: "files", elementName: "files", elementType: "files", className: "w-full" }

    ]

    return (
        <div className="w-full">
            <form onSubmit={onSubmitHandler} className="flex w-full flex-wrap justify-between">
                {!quoteData && <Loader />}
                {quoteData && FormElements.map((item, index) => (
                    <div key={index} className={item.className ? item.className : 'w-full'}>
                        <FormElement
                            elementLabel={item.elementLabel}
                            elementName={item.elementName}
                            elementType={item.elementType}
                            elementValue={quoteData[item.elementName]}
                            elementOptions={quoteSuppliers}
                            fileDeleteHandler={fileDeleteHandler}
                            fileRemoveHandler={fileRemoveHandler}
                            fileReorderHandler={fileReorderHandler}
                            onChangeHandler={(e) => {
                                setQuoteData((prevState) => {
                                    console.log("hey");
                                    return {
                                        ...prevState,
                                        [item.elementName]: e.target.value
                                    }
                                })
                            }} />
                    </div>
                ))}
                <Dropzone fileUploadHandler={fileUploadHandler} />
                <div className='w-full mb-8 pt-8 border-b border-gray-200'></div>
                <div className="flex items-center justify-between w-full">
                    <button className="btn flex items-center" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                        </svg>

                        <span className='ml-2'>Save</span>
                    </button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                        e.preventDefault();
                        setIsOpen2(true);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Delete</span>
                    </button>
                </div>
            </form>
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        props.setDeleteSelect(props.quoteId)
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
        </div>
    )
}

export default EditQuoteForm